/* eslint id-length: 0 */
/* eslint-disable */
// last generation from Thu Dec 12 2024 23:46:53 GMT+0100 (Central European Standard Time)

import Requestor, { AdditionalOptions } from "@api/Requestor";
import * as Types from "@api/client-types";


class API {

    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedCardDTO>}		 A cancellable promise
     */
    addressGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedCardDTO> {
        return Requestor.run('/api/Address/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {UserCreationModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    administrationCreateUser(model: Types.UserCreationModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Administration/user', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {UsersListModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    administrationLockUsers(model: Types.UsersListModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Administration/lock', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {UsersListModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    administrationUnlockUsers(model: Types.UsersListModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Administration/unlock', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<Types.AdministrationGroupDTO[]>}		 A cancellable promise
     */
    administrationGetGroups(additionalOptions?: AdditionalOptions) : Promise<Types.AdministrationGroupDTO[]> {
        return Requestor.run('/api/Administration/groups', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {array}		 filters	filters
     * @param {integer}		 length	length
     * @param {Object}		 sort	sort
     * @param {integer}		 start	start
     * @return {Promise<Types.SearchResultOfAdministrationUserDTO>}		 A cancellable promise
     */
    administrationGetUsers(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfAdministrationUserDTO> {
        return Requestor.run('/api/Administration/users', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {array}		 filters	filters
     * @param {integer}		 length	length
     * @param {Object}		 sort	sort
     * @param {integer}		 start	start
     * @return {Promise<Types.SearchResultOfAdministrationUserDTO>}		 A cancellable promise
     */
    administrationGetChildrenUsers(id: string, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfAdministrationUserDTO> {
        return Requestor.run('/api/Administration/{id}/children-users', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 id	id
     * @return {Promise<string[]>}		 A cancellable promise
     */
    administrationGetUserParents(id: string, additionalOptions?: AdditionalOptions) : Promise<string[]> {
        return Requestor.run('/api/Administration/{id}/user-parents', 'GET', {id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<object>}		 A cancellable promise
     */
    administrationUserCanCreateUsers(additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Administration/user/can-create', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<object>}		 A cancellable promise
     */
    administrationSuggestPassword(additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Administration/suggest-password', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {AggregatedFeaturesParameters}		 parameters	This parameter will be add in the body of the request
     * @return {Promise<Types.CarrierTreatmentStatisticsDTO>}		 A cancellable promise
     */
    aggregatedFeaturesCarrierTreatmentStatistics(parameters: Types.AggregatedFeaturesParameters, additionalOptions?: AdditionalOptions) : Promise<Types.CarrierTreatmentStatisticsDTO> {
        return Requestor.run('/api/AggregatedFeatures/CarrierTreatmentStatistics', 'POST', {}, {}, parameters, additionalOptions);
    }


    /**
     * @async
     * @param {AggregatedFeaturesParameters}		 parameters	This parameter will be add in the body of the request
     * @return {Promise<Types.CarrierPlanSponsorStatisticsDTO>}		 A cancellable promise
     */
    aggregatedFeaturesCarrierPlanSponsorStatistics(parameters: Types.AggregatedFeaturesParameters, additionalOptions?: AdditionalOptions) : Promise<Types.CarrierPlanSponsorStatisticsDTO> {
        return Requestor.run('/api/AggregatedFeatures/CarrierPlanSponsorStatistics', 'POST', {}, {}, parameters, additionalOptions);
    }


    /**
     * @async
     * @param {AggregatedFeaturesParameters}		 parameters	This parameter will be add in the body of the request
     * @return {Promise<Types.AggregatedStatisticsDTO>}		 A cancellable promise
     */
    aggregatedFeaturesAggregatedStatistics(parameters: Types.AggregatedFeaturesParameters, additionalOptions?: AdditionalOptions) : Promise<Types.AggregatedStatisticsDTO> {
        return Requestor.run('/api/AggregatedFeatures/AggregatedStatistics', 'POST', {}, {}, parameters, additionalOptions);
    }


    /**
     * @async
     * @param {AggregatedFeaturesParameters}		 parameters	This parameter will be add in the body of the request
     * @return {Promise<Types.AggregatedFeaturesDTO>}		 A cancellable promise
     */
    aggregatedFeaturesScatterChartFeatures(parameters: Types.AggregatedFeaturesParameters, additionalOptions?: AdditionalOptions) : Promise<Types.AggregatedFeaturesDTO> {
        return Requestor.run('/api/AggregatedFeatures/ScatterChartFeatures', 'POST', {}, {}, parameters, additionalOptions);
    }


    /**
     * @async
     * @param {AggregatedFeaturesParameters}		 parameters	This parameter will be add in the body of the request
     * @return {Promise<Types.TimelineAggregatedFeaturesDTO>}		 A cancellable promise
     */
    aggregatedFeaturesTimelineChartFeatures(parameters: Types.AggregatedFeaturesParameters, additionalOptions?: AdditionalOptions) : Promise<Types.TimelineAggregatedFeaturesDTO> {
        return Requestor.run('/api/AggregatedFeatures/TimelineChartFeatures', 'POST', {}, {}, parameters, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.CommentDTO[]>}		 A cancellable promise
     */
    alertCommentGetAll(alertId: number, additionalOptions?: AdditionalOptions) : Promise<Types.CommentDTO[]> {
        return Requestor.run('/api/alert/{alertId}/comment', 'GET', {alertId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @param {CommentModel}		 commentModel	This parameter will be add in the body of the request
     * @return {Promise<Types.CommentDTO>}		 A cancellable promise
     */
    alertCommentAddComment(alertId: number, commentModel: Types.CommentModel, additionalOptions?: AdditionalOptions) : Promise<Types.CommentDTO> {
        return Requestor.run('/api/alert/{alertId}/comment', 'POST', {alertId}, {}, commentModel, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @param {CommentModel}		 commentModel	This parameter will be add in the body of the request
     * @return {Promise<Types.CommentDTO>}		 A cancellable promise
     */
    alertCommentEditComment(alertId: number, commentModel: Types.CommentModel, additionalOptions?: AdditionalOptions) : Promise<Types.CommentDTO> {
        return Requestor.run('/api/alert/{alertId}/comment', 'PUT', {alertId}, {}, commentModel, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<object>}		 A cancellable promise
     */
    alertCommentRemoveComment(alertId: number, id: number, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/alert/{alertId}/comment/{id}', 'DELETE', {alertId, id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.AlertDTO[]>}		 A cancellable promise
     */
    alertContainerGetAlertsById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.AlertDTO[]> {
        return Requestor.run('/api/AlertContainer/{id}/alerts', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 type	type
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedAlertTableRowDTO[]>}		 A cancellable promise
     */
    alertContainerComputedViewGetAlertsById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedAlertTableRowDTO[]> {
        return Requestor.run('/api/AlertContainer/{id}/alerts/computed-view', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.AlertContainerDTO>}		 A cancellable promise
     */
    alertContainerGetById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.AlertContainerDTO> {
        return Requestor.run('/api/AlertContainer/{id}', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<Types.ApplicationUserDTO[]>}		 A cancellable promise
     */
    alertContainerGetAssignableUsersForAlertContainer(id: number, additionalOptions?: AdditionalOptions) : Promise<Types.ApplicationUserDTO[]> {
        return Requestor.run('/api/AlertContainer/{id}/assign', 'GET', {id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 userId	userId
     * @return {Promise<Types.ApplicationUserDTO[]>}		 A cancellable promise
     */
    alertContainerSetAssignment(id: number, userId: string, additionalOptions?: AdditionalOptions) : Promise<Types.ApplicationUserDTO[]> {
        return Requestor.run('/api/AlertContainer/{id}/assign/{userId}', 'PUT', {id, userId}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 userId	userId
     * @return {Promise<Types.ApplicationUserDTO[]>}		 A cancellable promise
     */
    alertContainerRemoveAssignment(id: number, userId: string, additionalOptions?: AdditionalOptions) : Promise<Types.ApplicationUserDTO[]> {
        return Requestor.run('/api/AlertContainer/{id}/assign/{userId}', 'DELETE', {id, userId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfAlertContainerDTO>}		 A cancellable promise
     */
    alertContainerSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfAlertContainerDTO> {
        return Requestor.run('/api/AlertContainer/search/all', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedAlertContainerTableRowDTO>}		 A cancellable promise
     */
    alertContainerComputedViewSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedAlertContainerTableRowDTO> {
        return Requestor.run('/api/AlertContainer/search/all/computed-view', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 type	type
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfAlertContainerDTO>}		 A cancellable promise
     */
    alertContainerSearchByType(type: string, model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfAlertContainerDTO> {
        return Requestor.run('/api/AlertContainer/search/{type}', 'POST', {type}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 type	type
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedAlertContainerTableRowDTO>}		 A cancellable promise
     */
    alertContainerComputedViewSearchByType(type: string, model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedAlertContainerTableRowDTO> {
        return Requestor.run('/api/AlertContainer/search/{type}/computed-view', 'POST', {type}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {AlertContainerStatusModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    alertContainerSetValidationStatus(id: number, model: Types.AlertContainerStatusModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/AlertContainer/{id}/validation', 'POST', {id}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.AlertDTO>}		 A cancellable promise
     */
    alertGetById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.AlertDTO> {
        return Requestor.run('/api/Alert/{id}', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<boolean>}		 A cancellable promise
     */
    alertCanCreateAlert(type: string, id: number, additionalOptions?: AdditionalOptions) : Promise<boolean> {
        return Requestor.run('/api/Alert/canCreateAlert/{type}/{id}', 'GET', {type, id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 type	type
     * @return {Promise<Types.CreateAlertInfoDTO>}		 A cancellable promise
     */
    alertGetInformationForCreateAlert(type: string, additionalOptions?: AdditionalOptions) : Promise<Types.CreateAlertInfoDTO> {
        return Requestor.run('/api/Alert/createModalInfo/{type}', 'POST', {type}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 exportName	exportName
     * @param {AlertsCustomExportModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    alertTableCustomExport(exportName: string, model: Types.AlertsCustomExportModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Alert/tableCustomExport/{exportName}', 'POST', {exportName}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 scenarioId	scenarioId
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.MultiCsvDTO>}		 A cancellable promise
     */
    alertExportHistory(scenarioId: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.MultiCsvDTO> {
        return Requestor.run('/api/Alert/export/scenario/{scenarioId}/history', 'POST', {scenarioId}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 scenarioId	scenarioId
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 pageType	pageType
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @param {integer=}		 _	_
     * @return {Promise<Types.HistoryDTO>}		 A cancellable promise
     */
    alertGetHistory(scenarioId: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.HistoryDTO> {
        return Requestor.run('/api/Alert/scenario/{scenarioId}/history', 'POST', {scenarioId}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfAlertDTO>}		 A cancellable promise
     */
    alertSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfAlertDTO> {
        return Requestor.run('/api/Alert/search/all', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 type	type
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfAlertDTO>}		 A cancellable promise
     */
    alertSearchByType(type: string, model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfAlertDTO> {
        return Requestor.run('/api/Alert/search/{type}', 'POST', {type}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedAlertTableRowDTO>}		 A cancellable promise
     */
    alertComputedViewSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedAlertTableRowDTO> {
        return Requestor.run('/api/Alert/search/all/computed-view', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 type	type
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedAlertTableRowDTO>}		 A cancellable promise
     */
    alertComputedViewSearchByType(type: string, model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedAlertTableRowDTO> {
        return Requestor.run('/api/Alert/search/{type}/computed-view', 'POST', {type}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<number[]>}		 A cancellable promise
     */
    alertGetByTypeByResourceId(type: string, id: number, additionalOptions?: AdditionalOptions) : Promise<number[]> {
        return Requestor.run('/api/Alert/{type}/{id}', 'GET', {type, id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<Types.AlertContainerDTO[]>}		 A cancellable promise
     */
    alertGetContainersByAlertId(id: number, additionalOptions?: AdditionalOptions) : Promise<Types.AlertContainerDTO[]> {
        return Requestor.run('/api/Alert/{id}/containers', 'GET', {id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {IdListModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.ApplicationUserDTO[]>}		 A cancellable promise
     */
    alertGetAssignableUsersForAlert(model: Types.IdListModel, additionalOptions?: AdditionalOptions) : Promise<Types.ApplicationUserDTO[]> {
        return Requestor.run('/api/Alert/assignables', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {AlertAssignementModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    alertSetAssignment(model: Types.AlertAssignementModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Alert/assign', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<Types.ApplicationUserDTO[]>}		 A cancellable promise
     */
    alertSetSelfAssignment(id: number, additionalOptions?: AdditionalOptions) : Promise<Types.ApplicationUserDTO[]> {
        return Requestor.run('/api/Alert/{id}/selfassign', 'PUT', {id}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {ActivationStatusModel}		 activationStatusModel	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    alertSetActivationStatuses(activationStatusModel: Types.ActivationStatusModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Alert/activationstatuses', 'POST', {}, {}, activationStatusModel, additionalOptions);
    }


    /**
     * @async
     * @param {DeletionModel}		 deletionModel	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    alertDelete(deletionModel: Types.DeletionModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Alert/delete', 'POST', {}, {}, deletionModel, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 scenarioId	scenarioId
     * @return {Promise<object>}		 A cancellable promise
     */
    alertEnableScenario(alertId: number, scenarioId: number, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Alert/{alertId}/scenario/{scenarioId}/enable', 'PUT', {alertId, scenarioId}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 scenarioId	scenarioId
     * @return {Promise<object>}		 A cancellable promise
     */
    alertDisableScenario(alertId: number, scenarioId: number, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Alert/{alertId}/scenario/{scenarioId}/disable', 'PUT', {alertId, scenarioId}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {IdentityStatusModel}		 identityStatuses	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    alertSetIdentityStatuses(identityStatuses: Types.IdentityStatusModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Alert/identitystatuses', 'PUT', {}, {}, identityStatuses, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {AlertGuidelineModel}		 guidelineModel	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    alertSetInvestigationGuideline(id: number, guidelineModel: Types.AlertGuidelineModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Alert/{id}/investigationguideline', 'PUT', {id}, {}, guidelineModel, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<Types.GetActivitiesByAlertIdResponse[]>}		 A cancellable promise
     */
    alertGetActivities(id: number, additionalOptions?: AdditionalOptions) : Promise<Types.GetActivitiesByAlertIdResponse[]> {
        return Requestor.run('/api/Alert/{id}/activities', 'GET', {id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 type	type
     * @param {RelatedNetworkAlertsModel}		 explorationNetworkNodes	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 targetId	targetId
     * @param {integer=}		 containerId	containerId
     * @param {integer=}		 alertId	alertId
     * @param {integer=}		 scenarioId	scenarioId
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfRelatedAlertDTO>}		 A cancellable promise
     */
    alertSearchRelatedAlerts(type: string, explorationNetworkNodes: Types.RelatedNetworkAlertsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfRelatedAlertDTO> {
        return Requestor.run('/api/Alert/{type}/related-alerts', 'POST', {type}, queryParameters, explorationNetworkNodes, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 type	type
     * @param {RelatedNetworkAlertsModel}		 explorationNetworkNodes	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 targetId	targetId
     * @param {integer=}		 containerId	containerId
     * @param {integer=}		 alertId	alertId
     * @param {integer=}		 scenarioId	scenarioId
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfComputedRelatedAlertTableRowDTO>}		 A cancellable promise
     */
    alertSearchRelatedAlertsComputedView(type: string, explorationNetworkNodes: Types.RelatedNetworkAlertsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedRelatedAlertTableRowDTO> {
        return Requestor.run('/api/Alert/{type}/related-alerts/computed-view', 'POST', {type}, queryParameters, explorationNetworkNodes, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @param {AlertMetadataModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    alertMetadataSaveForm(alertId: number, model: Types.AlertMetadataModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/AlertMetadata/{alertId}', 'POST', {alertId}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.AlertMetadataDTO>}		 A cancellable promise
     */
    alertMetadataGetForm(alertId: number, additionalOptions?: AdditionalOptions) : Promise<Types.AlertMetadataDTO> {
        return Requestor.run('/api/AlertMetadata/{alertId}', 'GET', {alertId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 formType	formType
     * @return {Promise<Types.AlertMetadataFormDynamicValues>}		 A cancellable promise
     */
    alertMetadataGetMetadataFormDynamicValues(formType: string, additionalOptions?: AdditionalOptions) : Promise<Types.AlertMetadataFormDynamicValues> {
        return Requestor.run('/api/AlertMetadata/dynamic-values/{formType}', 'GET', {formType}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 state	state
     * @param {Object=}		 body	body
     * @return {Promise<Types.AlertDTO>}		 A cancellable promise
     */
    alertWorkflowHandleTransition(alertId: number, state: string, actionType: string, body: object, additionalOptions?: AdditionalOptions) : Promise<Types.AlertDTO> {
        return Requestor.run('/api/alert/qualification/{alertId}/transition/{state}/{actionType}', 'POST', {alertId, state, actionType}, {}, body, additionalOptions);
    }


    /**
     * @async
     * @param {BulkTransitionModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    alertWorkflowHandleBulkTransition(model: Types.BulkTransitionModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/transition/bulk', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @return {Promise<object>}		 A cancellable promise
     */
    alertWorkflowRevertTransition(alertId: number, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/alert/qualification/{alertId}/revert', 'POST', {alertId}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 actionType	actionType
     * @return {Promise<Types.WorkflowActionConfigurationDTO>}		 A cancellable promise
     */
    alertWorkflowActionConfiguration(actionType: string, additionalOptions?: AdditionalOptions) : Promise<Types.WorkflowActionConfigurationDTO> {
        return Requestor.run('/api/alert/qualification/configuration/{actionType}', 'GET', {actionType}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {AmountModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    alertWorkflowSetAmount(id: number, model: Types.AmountModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/alert/qualification/{id}/amount', 'POST', {id}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {UiAlfaContainerModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<object>}		 A cancellable promise
     */
    alfaContainerSetCoordination(id: number, model: Types.UiAlfaContainerModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/AlfaContainer/{id}/coordinate', 'PUT', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<object>}		 A cancellable promise
     */
    authenticationLoginPage(additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/Authentication/login', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {LoginModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 returnState	returnState
     * @return {Promise<object>}		 A cancellable promise
     */
    authenticationLogin(model: Types.LoginModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/Authentication/login', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 provider	provider
     * @return {Promise<object>}		 A cancellable promise
     */
    authenticationExternalLoginPage(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/Authentication/externallogin', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<object>}		 A cancellable promise
     */
    authenticationLogout(additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/Authentication/logout', 'POST', {}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedCardDTO>}		 A cancellable promise
     */
    bankAccountGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedCardDTO> {
        return Requestor.run('/api/BankAccount/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 targetType	targetType
     * @param {string}		 targetId	targetId
     * @param {string=}		 subTarget	subTarget
     * @param {string=}		 subTargetId	subTargetId
     * @return {Promise<Types.SearchResultOfCaseManagementDocumentDTO>}		 A cancellable promise
     */
    caseManagementDocumentsGetDocuments(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfCaseManagementDocumentDTO> {
        return Requestor.run('/api/case-management-documents', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {array=}		 documentsMetadata	documentsMetadata
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 targetType	targetType
     * @param {string}		 targetId	targetId
     * @param {string=}		 subTarget	subTarget
     * @param {string=}		 subTargetId	subTargetId
     * @return {Promise<Types.SearchResultOfCaseManagementDocumentDTO>}		 A cancellable promise
     */
    caseManagementDocumentsCreateDocuments(documentsMetadata: Types.CaseManagementDocumentMetadataModel[], queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfCaseManagementDocumentDTO> {
        return Requestor.run('/api/case-management-documents', 'POST', {}, queryParameters, documentsMetadata, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 blobId	blobId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 targetType	targetType
     * @param {string}		 targetId	targetId
     * @param {string=}		 subTarget	subTarget
     * @param {string=}		 subTargetId	subTargetId
     * @return {Promise<Types.CaseManagementDocumentDTO>}		 A cancellable promise
     */
    caseManagementDocumentsGetDocument(blobId: string, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.CaseManagementDocumentDTO> {
        return Requestor.run('/api/case-management-documents/{blobId}', 'GET', {blobId}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 blobId	blobId
     * @param {DocumentUpdateMetadataModel}		 documentMetadata	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 targetType	targetType
     * @param {string}		 targetId	targetId
     * @param {string=}		 subTarget	subTarget
     * @param {string=}		 subTargetId	subTargetId
     * @return {Promise<object>}		 A cancellable promise
     */
    caseManagementDocumentsDocumentMetadataUpdate(blobId: string, documentMetadata: Types.DocumentUpdateMetadataModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/case-management-documents/{blobId}', 'PUT', {blobId}, queryParameters, documentMetadata, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 blobId	blobId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 targetType	targetType
     * @param {string}		 targetId	targetId
     * @param {string=}		 subTarget	subTarget
     * @param {string=}		 subTargetId	subTargetId
     * @param {boolean=}		 updateAuditTrail	updateAuditTrail
     * @return {Promise<object>}		 A cancellable promise
     */
    caseManagementDocumentsDelete(blobId: string, queryParameters?: object, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/case-management-documents/{blobId}', 'DELETE', {blobId}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {array=}		 ids	ids
     * @return {Promise<object>}		 A cancellable promise
     */
    caseManagementDocumentsStatuses(ids: string[], additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/case-management-documents/statuses', 'POST', {}, {}, ids, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 blobId	blobId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 targetType	targetType
     * @param {string}		 targetId	targetId
     * @param {string=}		 subTarget	subTarget
     * @param {string=}		 subTargetId	subTargetId
     * @return {Promise<object>}		 A cancellable promise
     */
    caseManagementDocumentsUpload(blobId: string, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/case-management-documents/{blobId}/upload', 'POST', {blobId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 blobId	blobId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 targetType	targetType
     * @param {string}		 targetId	targetId
     * @param {string=}		 subTarget	subTarget
     * @param {string=}		 subTargetId	subTargetId
     * @param {boolean=}		 isView	isView
     * @return {Promise<object>}		 A cancellable promise
     */
    caseManagementDocumentsDownloadDocument(blobId: string, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/case-management-documents/{blobId}/download', 'POST', {blobId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 blobId	blobId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 targetType	targetType
     * @param {string}		 targetId	targetId
     * @param {string=}		 subTarget	subTarget
     * @param {string=}		 subTargetId	subTargetId
     * @return {Promise<object>}		 A cancellable promise
     */
    caseManagementDocumentsTrackDownloadDocument(blobId: string, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/case-management-documents/{blobId}/trackDownload', 'POST', {blobId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 blobId	blobId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 targetType	targetType
     * @param {string}		 targetId	targetId
     * @param {string=}		 subTarget	subTarget
     * @param {string=}		 subTargetId	subTargetId
     * @return {Promise<Types.DocumentPermissionsDTO>}		 A cancellable promise
     */
    caseManagementDocumentsPermissions(blobId: string, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.DocumentPermissionsDTO> {
        return Requestor.run('/api/case-management-documents/{blobId}/permissions', 'GET', {blobId}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 claimId	claimId
     * @return {Promise<Types.CmsClaimCommentDTO[]>}		 A cancellable promise
     */
    claimCommentGetAll(claimId: string, additionalOptions?: AdditionalOptions) : Promise<Types.CmsClaimCommentDTO[]> {
        return Requestor.run('/api/cms-claims/{claimId}/comment', 'GET', {claimId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 claimId	claimId
     * @param {CmsClaimCommentModel}		 commentModel	This parameter will be add in the body of the request
     * @return {Promise<Types.CmsClaimCommentDTO>}		 A cancellable promise
     */
    claimCommentAddComment(claimId: string, commentModel: Types.CmsClaimCommentModel, additionalOptions?: AdditionalOptions) : Promise<Types.CmsClaimCommentDTO> {
        return Requestor.run('/api/cms-claims/{claimId}/comment', 'POST', {claimId}, {}, commentModel, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 claimId	claimId
     * @param {CmsClaimCommentModel}		 commentModel	This parameter will be add in the body of the request
     * @return {Promise<Types.CmsClaimCommentDTO>}		 A cancellable promise
     */
    claimCommentEditComment(claimId: string, commentModel: Types.CmsClaimCommentModel, additionalOptions?: AdditionalOptions) : Promise<Types.CmsClaimCommentDTO> {
        return Requestor.run('/api/cms-claims/{claimId}/comment', 'PUT', {claimId}, {}, commentModel, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 commentId	commentId
     * @return {Promise<object>}		 A cancellable promise
     */
    claimCommentRemoveComment(claimId: string, commentId: string, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/cms-claims/{claimId}/comment/{commentId}', 'DELETE', {claimId, commentId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @param {integer=}		 alertId	alertId
     * @return {Promise<Types.UnitaryPageDTO>}		 A cancellable promise
     */
    claimGetById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.UnitaryPageDTO> {
        return Requestor.run('/api/Claim/{id}', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 referenceId	referenceId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ValueTupleOfLongAndLong>}		 A cancellable promise
     */
    claimGetByReferenceId(referenceId: string, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ValueTupleOfLongAndLong> {
        return Requestor.run('/api/Claim/reference/{referenceId}', 'GET', {referenceId}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedCardDTO>}		 A cancellable promise
     */
    claimGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedCardDTO> {
        return Requestor.run('/api/Claim/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {AlertCreationModalFieldModel}		 alertCreationModalFieldModel	This parameter will be add in the body of the request
     * @return {Promise<number>}		 A cancellable promise
     */
    claimCreateAlert(id: number, alertCreationModalFieldModel: Types.AlertCreationModalFieldModel, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Claim/{id}/create-alert', 'POST', {id}, {}, alertCreationModalFieldModel, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfClaimDTO>}		 A cancellable promise
     */
    claimSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfClaimDTO> {
        return Requestor.run('/api/Claim/search', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedTableRowDTO>}		 A cancellable promise
     */
    claimComputedViewSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedTableRowDTO> {
        return Requestor.run('/api/Claim/search/computed-view', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 query	query
     * @param {integer=}		 start	start
     * @param {integer=}		 size	size
     * @return {Promise<Types.SearchResultOfClaimQuicksearchDTO>}		 A cancellable promise
     */
    claimQuickSearch(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfClaimQuicksearchDTO> {
        return Requestor.run('/api/Claim/quicksearch', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ClaimMetaDataDTO>}		 A cancellable promise
     */
    claimGetClaimMetaData(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ClaimMetaDataDTO> {
        return Requestor.run('/api/Claim/{id}/history/meta-data', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {HistoryClaimDetailsParameters}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfSpecializableComputedDTO>}		 A cancellable promise
     */
    claimGetClaimDetailsByClaimId(id: number, model: Types.HistoryClaimDetailsParameters, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfSpecializableComputedDTO> {
        return Requestor.run('/api/Claim/{id}/claim-details', 'POST', {id}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.SearchResultOfDocumentDTO>}		 A cancellable promise
     */
    claimGetDocuments(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfDocumentDTO> {
        return Requestor.run('/api/Claim/{id}/documents', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {DocumentMetadataListModel}		 documentsMetadata	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfDocumentDTO>}		 A cancellable promise
     */
    claimCreateDocuments(id: number, documentsMetadata: Types.DocumentMetadataListModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfDocumentDTO> {
        return Requestor.run('/api/Claim/{id}/documents', 'POST', {id}, {}, documentsMetadata, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ClaimNewPaymentRecommendationModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    claimSetPaymentRecommendation(id: number, model: Types.ClaimNewPaymentRecommendationModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Claim/{id}/payment-recommendation', 'POST', {id}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 entityId	entityId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.CmCorrespondenceDTO[]>}		 A cancellable promise
     */
    cmCorrespondenceGetAllByPage(entityType: string, entityId: string, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.CmCorrespondenceDTO[]> {
        return Requestor.run('/api/CmCorrespondence/{entityType}/{entityId}', 'GET', {entityType, entityId}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 entityId	entityId
     * @param {CmCorrespondenceCreateModel}		 correspondenceModel	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<object>}		 A cancellable promise
     */
    cmCorrespondenceCreate(entityType: string, entityId: string, correspondenceModel: Types.CmCorrespondenceCreateModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/CmCorrespondence/{entityType}/{entityId}', 'POST', {entityType, entityId}, queryParameters, correspondenceModel, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 entityId	entityId
     * @param {CmCorrespondenceUpdateModel}		 updatedCorrespondence	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.CmCorrespondenceDTO>}		 A cancellable promise
     */
    cmCorrespondenceUpdate(entityType: string, entityId: string, updatedCorrespondence: Types.CmCorrespondenceUpdateModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.CmCorrespondenceDTO> {
        return Requestor.run('/api/CmCorrespondence/{entityType}/{entityId}', 'PUT', {entityType, entityId}, queryParameters, updatedCorrespondence, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 correspondenceId	correspondenceId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.CmCorrespondenceDTO>}		 A cancellable promise
     */
    cmCorrespondenceGetById(entityType: string, entityId: string, correspondenceId: string, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.CmCorrespondenceDTO> {
        return Requestor.run('/api/CmCorrespondence/{entityType}/{entityId}/{correspondenceId}', 'GET', {entityType, entityId, correspondenceId}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<object[]>}		 A cancellable promise
     */
    cmCorrespondenceGetEmailTemplates(additionalOptions?: AdditionalOptions) : Promise<object[]> {
        return Requestor.run('/api/CmCorrespondence/getEmailTemplates', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 templateId	templateId
     * @return {Promise<Types.CorrespondenceTemplate>}		 A cancellable promise
     */
    cmCorrespondenceGetTemplateInfoById(entityType: string, entityId: number, templateId: number, additionalOptions?: AdditionalOptions) : Promise<Types.CorrespondenceTemplate> {
        return Requestor.run('/api/CmCorrespondence/{entityType}/{entityId}/getTemplateInfoById/{templateId}', 'GET', {entityType, entityId, templateId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 pageNumber	pageNumber
     * @param {integer}		 pageSize	pageSize
     * @param {integer}		 totalCount	totalCount
     * @param {string}		 referenceCode	referenceCode
     * @return {Promise<Types.SearchResultOfCmsClaimDTO>}		 A cancellable promise
     */
    cmsClaimGetPagedClaims(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfCmsClaimDTO> {
        return Requestor.run('/api/cms-claims', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 accessLevel	accessLevel
     * @return {Promise<Types.ApplicationUserDTO[]>}		 A cancellable promise
     */
    cmsClaimGetAssignableUsers(accessLevel: string, additionalOptions?: AdditionalOptions) : Promise<Types.ApplicationUserDTO[]> {
        return Requestor.run('/api/cms-claims/assignables/{accessLevel}', 'GET', {accessLevel}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {array=}		 claimIds	claimIds
     * @param {string=}		 assigneeId	assigneeId
     * @return {Promise<object>}		 A cancellable promise
     */
    cmsClaimAssignUser(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/cms-claims/assign', 'POST', {}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 id	id
     * @return {Promise<Types.GetActivitiesByTargetResponse[]>}		 A cancellable promise
     */
    cmsClaimGetActivities(id: string, additionalOptions?: AdditionalOptions) : Promise<Types.GetActivitiesByTargetResponse[]> {
        return Requestor.run('/api/cms-claims/{id}/activities', 'GET', {id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.CollaborationDTO>}		 A cancellable promise
     */
    collaborationGetCollaborationByAlertId(alertId: number, additionalOptions?: AdditionalOptions) : Promise<Types.CollaborationDTO> {
        return Requestor.run('/api/Collaboration/alert/{alertId}', 'GET', {alertId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @param {CollaborationRequestModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    collaborationSendCollaborationRequest(alertId: number, model: Types.CollaborationRequestModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Collaboration/alert/{alertId}/collaborate', 'POST', {alertId}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {CorrespondenceCreateModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    correspondenceCreateCorrespondence(model: Types.CorrespondenceCreateModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Correspondence', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {CorrespondenceModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfCorrespondenceDTO>}		 A cancellable promise
     */
    correspondenceSearch(model: Types.CorrespondenceModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfCorrespondenceDTO> {
        return Requestor.run('/api/Correspondence/search', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 endpoint	endpoint
     * @return {Promise<object[]>}		 A cancellable promise
     */
    correspondenceGetEndpointValues(resourceType: string, endpoint: string, additionalOptions?: AdditionalOptions) : Promise<object[]> {
        return Requestor.run('/api/Correspondence/endpoints/{resourceType}/{endpoint}', 'GET', {resourceType, endpoint}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 id	id
     * @return {Promise<object>}		 A cancellable promise
     */
    correspondenceDeleteById(queryParameters?: object, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Correspondence/delete', 'DELETE',{}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedCardDTO>}		 A cancellable promise
     */
    deviceGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedCardDTO> {
        return Requestor.run('/api/Device/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 originalId	originalId
     * @param {string}		 transformationMethod	transformationMethod
     * @return {Promise<object>}		 A cancellable promise
     */
    documentGetById(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Document', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 entityId	entityId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.DocumentModalInfoDTO>}		 A cancellable promise
     */
    documentModalInformation(entityType: string, entityId: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.DocumentModalInfoDTO> {
        return Requestor.run('/api/Document/uploadModalInformation/{entityType}/{entityId}', 'GET', {entityType, entityId}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {IdListModel}		 ids	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    documentStatuses(ids: Types.IdListModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Document/statuses', 'POST', {}, {}, ids, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 docId	docId
     * @return {Promise<undefined>}		 A cancellable promise
     */
    documentUpload(alertId: number, docId: number, additionalOptions?: AdditionalOptions) : Promise<undefined> {
        return Requestor.run('/api/Document/{alertId}/upload/{docId}', 'POST', {alertId, docId}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<object>}		 A cancellable promise
     */
    documentMetadataDelete(id: number, queryParameters?: object, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Document/metadata/{id}', 'DELETE', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<Types.DocumentDTO>}		 A cancellable promise
     */
    documentGetDocumentMetadata(id: number, additionalOptions?: AdditionalOptions) : Promise<Types.DocumentDTO> {
        return Requestor.run('/api/Document/metadata/{id}', 'GET', {id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {DocumentUpdateMetadataModel}		 documentMetadata	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<object>}		 A cancellable promise
     */
    documentDocumentMetadataUpdate(id: number, documentMetadata: Types.DocumentUpdateMetadataModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Document/metadata/{id}', 'PUT', {id}, queryParameters, documentMetadata, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<object>}		 A cancellable promise
     */
    documentDownloadDocument(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Document/download/{id}', 'POST', {id}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<object>}		 A cancellable promise
     */
    documentTrackDownloadDocument(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Document/trackDownload/{id}', 'POST', {id}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<object>}		 A cancellable promise
     */
    documentViewDocument(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Document/view/{id}', 'POST', {id}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.DocumentPermissionsDTO>}		 A cancellable promise
     */
    documentPermissions(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.DocumentPermissionsDTO> {
        return Requestor.run('/api/Document/{id}/permissions', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {DocumentRequestSearchModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfDocumentRequestDTO>}		 A cancellable promise
     */
    documentRequestGetDocumentRequests(type: string, id: number, model: Types.DocumentRequestSearchModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfDocumentRequestDTO> {
        return Requestor.run('/api/DocumentRequest/get-documents/{type}/{id}', 'POST', {type, id}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 type	type
     * @param {RequestDocumentModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    documentRequestRequestDocument(type: string, model: Types.RequestDocumentModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/DocumentRequest/request-document/{type}', 'POST', {type}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @param {IdListModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    documentRequestUpdate(type: string, alertId: number, model: Types.IdListModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/DocumentRequest/update-documents-requests/{type}/{alertId}', 'POST', {type, alertId}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {array=}		 ids	ids
     * @return {Promise<object>}		 A cancellable promise
     */
    documentRequestDelete(type: string, alertId: number, queryParameters?: object, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/DocumentRequest/delete/{type}/{alertId}', 'DELETE', {type, alertId}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {DocumentRequestSearchModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.CsvDTO>}		 A cancellable promise
     */
    documentRequestExport(type: string, id: number, model: Types.DocumentRequestSearchModel, additionalOptions?: AdditionalOptions) : Promise<Types.CsvDTO> {
        return Requestor.run('/api/DocumentRequest/export/{type}/{id}', 'POST', {type, id}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.DocumentRequestDTO>}		 A cancellable promise
     */
    documentRequestWorkflowHandleTransition(type: string, documentId: number, state: string, actionType: string, alertId: number, additionalOptions?: AdditionalOptions) : Promise<Types.DocumentRequestDTO> {
        return Requestor.run('/api/document/workflow/{type}/{documentId}/transition/{state}/{actionType}/{alertId}', 'POST', {type, documentId, state, actionType, alertId}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 actionType	actionType
     * @return {Promise<Types.WorkflowActionConfigurationDTO>}		 A cancellable promise
     */
    documentRequestWorkflowActionConfiguration(actionType: string, additionalOptions?: AdditionalOptions) : Promise<Types.WorkflowActionConfigurationDTO> {
        return Requestor.run('/api/document/workflow/configuration/{actionType}', 'GET', {actionType}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @return {Promise<object>}		 A cancellable promise
     */
    documentRequestWorkflowRevertTransition(documentId: number, type: string, alertId: number, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/document/workflow/{documentId}/{type}/revert/{alertId}', 'POST', {documentId, type, alertId}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 id	id
     * @return {Promise<Types.ElectronicDeviceClaimSummaryDTO>}		 A cancellable promise
     */
    electronicDeviceClaimGetClaimSummaryById(id: string, additionalOptions?: AdditionalOptions) : Promise<Types.ElectronicDeviceClaimSummaryDTO> {
        return Requestor.run('/api/ElectronicDeviceClaim/{id}', 'GET', {id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 journey	journey
     * @return {Promise<Types.ElectronicDeviceClaimSummaryDTO>}		 A cancellable promise
     */
    electronicDeviceClaimLogJourneyStart(id: string, journey: string, additionalOptions?: AdditionalOptions) : Promise<Types.ElectronicDeviceClaimSummaryDTO> {
        return Requestor.run('/api/ElectronicDeviceClaim/{id}/LogJourneyStart/{journey}', 'POST', {id, journey}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 enumName	enumName
     * @param {EnumParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.EnumDTO[]>}		 A cancellable promise
     */
    enumGetValues(enumName: string, model: Types.EnumParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.EnumDTO[]> {
        return Requestor.run('/api/Enum/{enumName}', 'POST', {enumName}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 endpoint	endpoint
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 type	type
     * @return {Promise<Types.EnumValueDTO[]>}		 A cancellable promise
     */
    enumGetEndpointValues(source: string, endpoint: string, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.EnumValueDTO[]> {
        return Requestor.run('/api/Enum/{source}/endpoints/{endpoint}', 'GET', {source, endpoint}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.UnitaryPageDTO>}		 A cancellable promise
     */
    exposureGetById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.UnitaryPageDTO> {
        return Requestor.run('/api/Exposure/{id}', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {AlertCreationModalFieldModel}		 alertCreationModalFieldModel	This parameter will be add in the body of the request
     * @return {Promise<number>}		 A cancellable promise
     */
    exposureCreateAlert(id: number, alertCreationModalFieldModel: Types.AlertCreationModalFieldModel, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Exposure/{id}/create-alert', 'POST', {id}, {}, alertCreationModalFieldModel, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfExposureDTO>}		 A cancellable promise
     */
    exposureSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfExposureDTO> {
        return Requestor.run('/api/Exposure/search', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedTableRowDTO>}		 A cancellable promise
     */
    exposureComputedViewSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedTableRowDTO> {
        return Requestor.run('/api/Exposure/search/computed-view', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 query	query
     * @param {integer=}		 start	start
     * @param {integer=}		 size	size
     * @return {Promise<Types.SearchResultOfExposureQuicksearchDTO>}		 A cancellable promise
     */
    exposureQuickSearch(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfExposureQuicksearchDTO> {
        return Requestor.run('/api/Exposure/quicksearch', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<number>}		 A cancellable promise
     */
    exposureGetExposureClaimId(id: number, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Exposure/{id}/get-exposure-claim-id', 'GET', {id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {ForceTaskCreateModel}		 forceTaskCreateModel	This parameter will be add in the body of the request
     * @return {Promise<Types.ForceTaskDTO>}		 A cancellable promise
     */
    forceTaskCreateTask(forceTaskCreateModel: Types.ForceTaskCreateModel, additionalOptions?: AdditionalOptions) : Promise<Types.ForceTaskDTO> {
        return Requestor.run('/api/forcetask/create', 'POST', {}, {}, forceTaskCreateModel, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 targetId	targetId
     * @return {Promise<Types.ForceTaskDTO[]>}		 A cancellable promise
     */
    forceTaskGetTasksByTarget(targetType: string, targetId: string, additionalOptions?: AdditionalOptions) : Promise<Types.ForceTaskDTO[]> {
        return Requestor.run('/api/forcetask/searchByTarget/{targetType}/{targetId}', 'GET', {targetType, targetId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 targetId	targetId
     * @return {Promise<Types.ForceTaskDTO>}		 A cancellable promise
     */
    forceTaskGetForceTaskById(id: string, targetType: string, targetId: string, additionalOptions?: AdditionalOptions) : Promise<Types.ForceTaskDTO> {
        return Requestor.run('/api/forcetask/{id}/target/{targetType}/{targetId}', 'GET', {id, targetType, targetId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 status	status
     * @param {ForceTaskUpdateModel}		 forceTaskTarget	This parameter will be add in the body of the request
     * @return {Promise<Types.ForceTaskDTO>}		 A cancellable promise
     */
    forceTaskUpdateStatus(id: string, status: string, forceTaskTarget: Types.ForceTaskUpdateModel, additionalOptions?: AdditionalOptions) : Promise<Types.ForceTaskDTO> {
        return Requestor.run('/api/forcetask/{id}/updateStatus/{status}', 'PUT', {id, status}, {}, forceTaskTarget, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 assignee	assignee
     * @param {ForceTaskUpdateModel}		 forceTaskTarget	This parameter will be add in the body of the request
     * @return {Promise<Types.ForceTaskDTO>}		 A cancellable promise
     */
    forceTaskUpdateAssignee(id: string, assignee: string, forceTaskTarget: Types.ForceTaskUpdateModel, additionalOptions?: AdditionalOptions) : Promise<Types.ForceTaskDTO> {
        return Requestor.run('/api/forcetask/{id}/updateAssignee/{assignee}', 'PUT', {id, assignee}, {}, forceTaskTarget, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 id	id
     * @param {ForceTaskPerformTaskStartedEventModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    forceTaskPerformTaskStartedEvent(id: string, model: Types.ForceTaskPerformTaskStartedEventModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/forcetask/{id}/performStartedEvent', 'POST', {id}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 id	id
     * @param {ForceTaskCreateModel}		 forceTaskUpdateModel	This parameter will be add in the body of the request
     * @return {Promise<Types.ForceTaskDTO>}		 A cancellable promise
     */
    forceTaskUpdateTask(id: string, forceTaskUpdateModel: Types.ForceTaskCreateModel, additionalOptions?: AdditionalOptions) : Promise<Types.ForceTaskDTO> {
        return Requestor.run('/api/forcetask/{id}', 'PUT', {id}, {}, forceTaskUpdateModel, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 targetType	targetType
     * @param {string}		 targetId	targetId
     * @param {string}		 etag	etag
     * @return {Promise<object>}		 A cancellable promise
     */
    forceTaskDeleteTask(id: string, queryParameters?: object, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/forcetask/{id}', 'DELETE', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 targetId	targetId
     * @return {Promise<Types.ApplicationUserDTO[]>}		 A cancellable promise
     */
    forceTaskGetTaskAssignables(accessLevel: string, targetType: string, targetId: number, additionalOptions?: AdditionalOptions) : Promise<Types.ApplicationUserDTO[]> {
        return Requestor.run('/api/forcetask/assignables/{accessLevel}/{targetType}/{targetId}', 'GET', {accessLevel, targetType, targetId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {boolean}		 onlyForCurrentUser	onlyForCurrentUser
     * @param {integer=}		 page	page
     * @return {Promise<Types.ForceTaskDashboardPageDTO>}		 A cancellable promise
     */
    forceTaskGetTasksDashboard(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ForceTaskDashboardPageDTO> {
        return Requestor.run('/api/forcetask/getTasksDashboard', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<object>}		 A cancellable promise
     */
    frontIndex(additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {ForgotPasswordModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    identityForgotPassword(model: Types.ForgotPasswordModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Identity/forgot-password', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ResetPasswordModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    identityResetPassword(model: Types.ResetPasswordModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Identity/reset-password', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ChangePasswordModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    identityChangePassword(model: Types.ChangePasswordModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Identity/change-password', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ValidatePasswordModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    identityValidatePassword(model: Types.ValidatePasswordModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Identity/validate-password', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<Types.ApplicationUserDTO[]>}		 A cancellable promise
     */
    identityGetAssignables(additionalOptions?: AdditionalOptions) : Promise<Types.ApplicationUserDTO[]> {
        return Requestor.run('/api/Identity/assignables', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 offset	offset
     * @param {integer}		 limit	limit
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ClaimHistoryDTO>}		 A cancellable promise
     */
    insuranceDetectionNetworkGetPersonClaimsHistory(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ClaimHistoryDTO> {
        return Requestor.run('/api/IDN/Persons/{id}/ClaimsHistory', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 offset	offset
     * @param {integer}		 limit	limit
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ClaimHistoryDTO>}		 A cancellable promise
     */
    insuranceDetectionNetworkGetVehicleClaimsHistory(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ClaimHistoryDTO> {
        return Requestor.run('/api/IDN/Vehicles/{id}/ClaimsHistory', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 sourceId	sourceId
     * @return {Promise<Types.IdnVehicleDetailsDTO>}		 A cancellable promise
     */
    insuranceDetectionNetworkGetVehicleDetails(carrierId: string, claimId: string, sourceId: string, additionalOptions?: AdditionalOptions) : Promise<Types.IdnVehicleDetailsDTO> {
        return Requestor.run('/api/IDN/Carriers/{carrierId}/Claims/{claimId}/Vehicles/{sourceId}', 'GET', {carrierId, claimId, sourceId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 sourceId	sourceId
     * @return {Promise<Types.IdnPersonDetailsDTO>}		 A cancellable promise
     */
    insuranceDetectionNetworkGetPersonDetails(carrierId: string, claimId: string, sourceId: string, additionalOptions?: AdditionalOptions) : Promise<Types.IdnPersonDetailsDTO> {
        return Requestor.run('/api/IDN/Carriers/{carrierId}/Claims/{claimId}/Persons/{sourceId}', 'GET', {carrierId, claimId, sourceId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 claimId	claimId
     * @return {Promise<Types.IdnClaimInsightsDTO>}		 A cancellable promise
     */
    insuranceDetectionNetworkGetClaimInsights(claimId: number, additionalOptions?: AdditionalOptions) : Promise<Types.IdnClaimInsightsDTO> {
        return Requestor.run('/api/IDN/Claims/{claimId}/Insights', 'GET', {claimId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 exposureId	exposureId
     * @return {Promise<Types.IdnClaimInsightsDTO>}		 A cancellable promise
     */
    insuranceDetectionNetworkGetExsposureInsights(exposureId: number, additionalOptions?: AdditionalOptions) : Promise<Types.IdnClaimInsightsDTO> {
        return Requestor.run('/api/IDN/Exposures/{exposureId}/Insights', 'GET', {exposureId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedCardDTO>}		 A cancellable promise
     */
    intelligenceGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedCardDTO> {
        return Requestor.run('/api/Intelligence/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.UnitaryPageDTO>}		 A cancellable promise
     */
    intermediaryGetById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.UnitaryPageDTO> {
        return Requestor.run('/api/Intermediary/{id}', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedCardDTO>}		 A cancellable promise
     */
    intermediaryGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedCardDTO> {
        return Requestor.run('/api/Intermediary/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {AlertCreationModalFieldModel}		 alertCreationModalFieldModel	This parameter will be add in the body of the request
     * @return {Promise<number>}		 A cancellable promise
     */
    intermediaryCreateAlert(id: number, alertCreationModalFieldModel: Types.AlertCreationModalFieldModel, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Intermediary/{id}/create-alert', 'POST', {id}, {}, alertCreationModalFieldModel, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfIntermediaryDTO>}		 A cancellable promise
     */
    intermediarySearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfIntermediaryDTO> {
        return Requestor.run('/api/Intermediary/search', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedTableRowDTO>}		 A cancellable promise
     */
    intermediaryComputedViewSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedTableRowDTO> {
        return Requestor.run('/api/Intermediary/search/computed-view', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 query	query
     * @param {integer=}		 start	start
     * @param {integer=}		 size	size
     * @return {Promise<Types.SearchResultOfIntermediaryQuicksearchDTO>}		 A cancellable promise
     */
    intermediaryQuickSearch(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfIntermediaryQuicksearchDTO> {
        return Requestor.run('/api/Intermediary/quicksearch', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.MultiCsvDTO>}		 A cancellable promise
     */
    intermediaryExportHistory(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.MultiCsvDTO> {
        return Requestor.run('/api/Intermediary/export/{id}/history', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 pageType	pageType
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @param {integer=}		 scenarioId	scenarioId
     * @return {Promise<Types.HistoryDTO>}		 A cancellable promise
     */
    intermediaryGetHistory(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.HistoryDTO> {
        return Requestor.run('/api/Intermediary/{id}/history', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.GeolocationDTO[]>}		 A cancellable promise
     */
    intermediaryGetClaimsAddresses(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.GeolocationDTO[]> {
        return Requestor.run('/api/Intermediary/{id}/claims/addresses', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.SearchResultOfDocumentDTO>}		 A cancellable promise
     */
    intermediaryGetDocuments(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfDocumentDTO> {
        return Requestor.run('/api/Intermediary/{id}/documents', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {DocumentMetadataListModel}		 documentsMetadata	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfDocumentDTO>}		 A cancellable promise
     */
    intermediaryCreateDocuments(id: number, documentsMetadata: Types.DocumentMetadataListModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfDocumentDTO> {
        return Requestor.run('/api/Intermediary/{id}/documents', 'POST', {id}, {}, documentsMetadata, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 type	type
     * @return {Promise<Types.InvestigationEntitiesDTO>}		 A cancellable promise
     */
    investigationEntityGetLinkedEntitiesByType(alertId: number, type: string, additionalOptions?: AdditionalOptions) : Promise<Types.InvestigationEntitiesDTO> {
        return Requestor.run('/api/Investigation/{alertId}/entities/{type}', 'GET', {alertId, type}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 type	type
     * @param {InvestigationEntityCreateModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    investigationEntityCreate(alertId: number, type: string, model: Types.InvestigationEntityCreateModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Investigation/{alertId}/entities/{type}', 'POST', {alertId, type}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 type	type
     * @param {InvestigationEntitySearchModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.InvestigationEntitiesSearchResultDTO>}		 A cancellable promise
     */
    investigationEntitySearchEntitiesByType(alertId: number, type: string, model: Types.InvestigationEntitySearchModel, additionalOptions?: AdditionalOptions) : Promise<Types.InvestigationEntitiesSearchResultDTO> {
        return Requestor.run('/api/Investigation/{alertId}/entities/{type}/search', 'POST', {alertId, type}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 linkId	linkId
     * @param {InvestigationEntityQualificationUpdateModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    investigationEntityUpdateQualification(linkId: number, model: Types.InvestigationEntityQualificationUpdateModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Investigation/entities/{linkId}/qualification', 'POST', {linkId}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 linkId	linkId
     * @return {Promise<object>}		 A cancellable promise
     */
    investigationEntityUpdateSubjectOfInvestigation(linkId: number, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Investigation/entities/{linkId}/subject-of-investigation', 'POST', {linkId}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedCardDTO>}		 A cancellable promise
     */
    mailGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedCardDTO> {
        return Requestor.run('/api/Mail/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {EmailModel}		 email	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    mailerSend(email: Types.EmailModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Mailer/send', 'POST', {}, {}, email, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 targetTypeStr	targetTypeStr
     * @param {string}		 targetId	targetId
     * @param {string}		 locale	locale
     * @return {Promise<object>}		 A cancellable promise
     */
    mailerGetTemplates(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Mailer/templates', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedCardDTO>}		 A cancellable promise
     */
    nationalIdentifierGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedCardDTO> {
        return Requestor.run('/api/NationalIdentifier/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.NetworkUnitaryPageDTO>}		 A cancellable promise
     */
    networkGetById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.NetworkUnitaryPageDTO> {
        return Requestor.run('/api/Network/{id}', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {UiNetworkUpdateModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    networkUpdate(id: number, model: Types.UiNetworkUpdateModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Network/{id}', 'PUT', {id}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {NodeLabelModel}		 nodeLabelModel	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    networkGetNodeLabels(nodeLabelModel: Types.NodeLabelModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Network/node-labels', 'POST', {}, {}, nodeLabelModel, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<Types.NetworkComponentsDTO>}		 A cancellable promise
     */
    networkGetNetworkComponents(id: number, additionalOptions?: AdditionalOptions) : Promise<Types.NetworkComponentsDTO> {
        return Requestor.run('/api/Network/{id}/components', 'GET', {id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<object>}		 A cancellable promise
     */
    networkUpsertNetworkUserLastVisit(id: number, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Network/{id}/lastVisit', 'POST', {id}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {AlertCreationModalFieldModel}		 alertCreationModalFieldModel	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 id	id
     * @return {Promise<number>}		 A cancellable promise
     */
    networkCreateAlert(alertCreationModalFieldModel: Types.AlertCreationModalFieldModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Network', 'POST', {}, queryParameters, alertCreationModalFieldModel, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfNetworkDTO>}		 A cancellable promise
     */
    networkSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfNetworkDTO> {
        return Requestor.run('/api/Network/search', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 type	type
     * @param {integer}		 id	id
     * @return {Promise<Types.NetworkNameDTO[]>}		 A cancellable promise
     */
    networkGetReactiveNetworkNames(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.NetworkNameDTO[]> {
        return Requestor.run('/api/Network/getReactiveNetworkNames', 'POST', {}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 query	query
     * @param {integer=}		 start	start
     * @param {integer=}		 size	size
     * @return {Promise<Types.SearchResultOfNetworkQuicksearchDTO>}		 A cancellable promise
     */
    networkQuickSearch(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfNetworkQuicksearchDTO> {
        return Requestor.run('/api/Network/quicksearch', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 name	name
     * @return {Promise<object>}		 A cancellable promise
     */
    networkRename(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Network/{id}/rename', 'PUT', {id}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {array=}		 items	items
     * @return {Promise<Types.GeneratedNetworkModel[]>}		 A cancellable promise
     */
    networkSetNetworkBuilderItems(items: Types.GeneratedNetworkModel[], additionalOptions?: AdditionalOptions) : Promise<Types.GeneratedNetworkModel[]> {
        return Requestor.run('/api/Network/networkBuilderItems', 'POST', {}, {}, items, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<Types.GeneratedNetworkModel[]>}		 A cancellable promise
     */
    networkGetNetworkBuilderItems(additionalOptions?: AdditionalOptions) : Promise<Types.GeneratedNetworkModel[]> {
        return Requestor.run('/api/Network/networkBuilderItems', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {string=}		 type	type
     * @param {integer=}		 seedId	seedId
     * @param {boolean=}		 updateNetwork	updateNetwork
     * @return {Promise<number>}		 A cancellable promise
     */
    networkGetNetworkId(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Network/get-network-id', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {array=}		 models	models
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 unitaryPageType	unitaryPageType
     * @param {integer=}		 networkId	networkId
     * @return {Promise<Types.LightNetworkDTO>}		 A cancellable promise
     */
    networkExploreNeighbors(models: Types.GeneratedNetworkModel[], queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.LightNetworkDTO> {
        return Requestor.run('/api/Network/explore-neighbors', 'POST', {}, queryParameters, models, additionalOptions);
    }


    /**
     * @async
     * @param {array=}		 models	models
     * @return {Promise<number>}		 A cancellable promise
     */
    networkGenerateUserCreated(models: Types.GeneratedNetworkModel[], additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Network/generate-user-created', 'PUT', {}, {}, models, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {NodesAndEdgesModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.NodesAndEdgesDTO>}		 A cancellable promise
     */
    networkAddNodesAndEdges(id: number, model: Types.NodesAndEdgesModel, additionalOptions?: AdditionalOptions) : Promise<Types.NodesAndEdgesDTO> {
        return Requestor.run('/api/Network/add-nodes-and-edges/{id}', 'PUT', {id}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {NetworkExplorationSaveModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<number>}		 A cancellable promise
     */
    networkSaveExplorationNetwork(model: Types.NetworkExplorationSaveModel, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Network/save-exploration-network', 'PUT', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {array=}		 models	models
     * @return {Promise<number>}		 A cancellable promise
     */
    networkAddElements(id: number, models: Types.GeneratedNetworkModel[], additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Network/add-elements/{id}', 'PUT', {id}, {}, models, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 noteId	noteId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @return {Promise<object>}		 A cancellable promise
     */
    networkDeleteNodeNote(nodeId: number, noteId: number, queryParameters?: object, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Network/node/{nodeId}/note/{noteId}', 'DELETE', {nodeId, noteId}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 noteId	noteId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @param {string}		 content	content
     * @return {Promise<Types.CommentDTO>}		 A cancellable promise
     */
    networkEditNodeNote(nodeId: number, noteId: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.CommentDTO> {
        return Requestor.run('/api/Network/node/{nodeId}/note/{noteId}', 'PUT', {nodeId, noteId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 nodeId	nodeId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @param {string}		 content	content
     * @return {Promise<Types.CommentDTO>}		 A cancellable promise
     */
    networkCreateNodeNote(nodeId: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.CommentDTO> {
        return Requestor.run('/api/Network/node/{nodeId}/note', 'POST', {nodeId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 noteId	noteId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @return {Promise<object>}		 A cancellable promise
     */
    networkDeleteEdgeNote(firstNodeId: number, secondNodeId: number, noteId: number, queryParameters?: object, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Network/edge/{firstNodeId}/{secondNodeId}/note/{noteId}', 'DELETE', {firstNodeId, secondNodeId, noteId}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 noteId	noteId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @param {string}		 content	content
     * @return {Promise<Types.CommentDTO>}		 A cancellable promise
     */
    networkEditEdgeNote(firstNodeId: number, secondNodeId: number, noteId: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.CommentDTO> {
        return Requestor.run('/api/Network/edge/{firstNodeId}/{secondNodeId}/note/{noteId}', 'PUT', {firstNodeId, secondNodeId, noteId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 secondNodeId	secondNodeId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @param {string}		 content	content
     * @return {Promise<Types.CommentDTO>}		 A cancellable promise
     */
    networkCreateEdgeNote(firstNodeId: number, secondNodeId: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.CommentDTO> {
        return Requestor.run('/api/Network/edge/{firstNodeId}/{secondNodeId}/note', 'POST', {firstNodeId, secondNodeId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 secondNodeId	secondNodeId
     * @param {EdgeBetweenExistingNodeCreateModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @return {Promise<Types.NetworkLinkDTO>}		 A cancellable promise
     */
    networkCreateEdge(firstNodeId: number, secondNodeId: number, model: Types.EdgeBetweenExistingNodeCreateModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.NetworkLinkDTO> {
        return Requestor.run('/api/Network/edge/{firstNodeId}/{secondNodeId}', 'POST', {firstNodeId, secondNodeId}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 edgeId	edgeId
     * @param {EdgeBetweenExistingNodeCreateModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @return {Promise<Types.NetworkLinkDTO>}		 A cancellable promise
     */
    networkEditEdge(edgeId: number, model: Types.EdgeBetweenExistingNodeCreateModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.NetworkLinkDTO> {
        return Requestor.run('/api/Network/edge/{edgeId}', 'PUT', {edgeId}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 edgeId	edgeId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @param {integer}		 noteId	noteId
     * @return {Promise<object>}		 A cancellable promise
     */
    networkDeleteEdge(edgeId: number, queryParameters?: object, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Network/edge/{edgeId}', 'DELETE', {edgeId}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 sourceNodeId	sourceNodeId
     * @param {NodeCreateModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @return {Promise<Types.NetworkNodeCreateDTO>}		 A cancellable promise
     */
    networkCreateNode(sourceNodeId: number, model: Types.NodeCreateModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.NetworkNodeCreateDTO> {
        return Requestor.run('/api/Network/node/{sourceNodeId}', 'POST', {sourceNodeId}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 nodeId	nodeId
     * @param {NodeCreateModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @return {Promise<Types.NetworkNodeEditDTO>}		 A cancellable promise
     */
    networkEditNode(nodeId: number, model: Types.NodeCreateModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.NetworkNodeEditDTO> {
        return Requestor.run('/api/Network/node/{nodeId}', 'PUT', {nodeId}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 nodeId	nodeId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @return {Promise<object>}		 A cancellable promise
     */
    networkDeleteNode(nodeId: number, queryParameters?: object, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Network/node/{nodeId}', 'DELETE', {nodeId}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {IdListModel}		 nodesId	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @return {Promise<object>}		 A cancellable promise
     */
    networkHideNode(nodesId: Types.IdListModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Network/nodes/hide', 'POST', {}, queryParameters, nodesId, additionalOptions);
    }


    /**
     * @async
     * @param {IdListModel}		 nodesId	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @return {Promise<object>}		 A cancellable promise
     */
    networkUnhideNode(nodesId: Types.IdListModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Network/nodes/unhide', 'POST', {}, queryParameters, nodesId, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 networkId	networkId
     * @param {NodeGroupCreationModel}		 nodes	This parameter will be add in the body of the request
     * @return {Promise<Types.NetworkNodeGroupManipulationWithLabelInfoDTO>}		 A cancellable promise
     */
    networkCreateNodeGroup(networkId: number, nodes: Types.NodeGroupCreationModel, additionalOptions?: AdditionalOptions) : Promise<Types.NetworkNodeGroupManipulationWithLabelInfoDTO> {
        return Requestor.run('/api/Network/{networkId}/group', 'PUT', {networkId}, {}, nodes, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 groupId	groupId
     * @param {NodeGroupModel}		 nodes	This parameter will be add in the body of the request
     * @return {Promise<Types.NetworkNodeGroupManipulationDTO>}		 A cancellable promise
     */
    networkAddNodesToNodeGroup(networkId: number, groupId: number, nodes: Types.NodeGroupModel, additionalOptions?: AdditionalOptions) : Promise<Types.NetworkNodeGroupManipulationDTO> {
        return Requestor.run('/api/Network/{networkId}/group/{groupId}/node-add', 'POST', {networkId, groupId}, {}, nodes, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 groupId	groupId
     * @param {NodeGroupModel}		 nodes	This parameter will be add in the body of the request
     * @return {Promise<Types.NetworkNodeGroupManipulationDTO>}		 A cancellable promise
     */
    networkRemoveNodesFromNodeGroup(networkId: number, groupId: number, nodes: Types.NodeGroupModel, additionalOptions?: AdditionalOptions) : Promise<Types.NetworkNodeGroupManipulationDTO> {
        return Requestor.run('/api/Network/{networkId}/group/{groupId}/node-remove', 'POST', {networkId, groupId}, {}, nodes, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 groupId	groupId
     * @param {NodeGroupModel}		 nodes	This parameter will be add in the body of the request
     * @return {Promise<Types.NetworkNodeGroupManipulationDTO>}		 A cancellable promise
     */
    networkUngroupNodes(networkId: number, groupId: number, nodes: Types.NodeGroupModel, additionalOptions?: AdditionalOptions) : Promise<Types.NetworkNodeGroupManipulationDTO> {
        return Requestor.run('/api/Network/{networkId}/group/{groupId}/ungroup', 'POST', {networkId, groupId}, {}, nodes, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 groupId	groupId
     * @param {NodeGroupInfoModel}		 info	This parameter will be add in the body of the request
     * @return {Promise<Types.NetworkNodeGroupManipulationWithLabelInfoDTO>}		 A cancellable promise
     */
    networkUpdateNetworkNodeGroupInfo(networkId: number, groupId: number, info: Types.NodeGroupInfoModel, additionalOptions?: AdditionalOptions) : Promise<Types.NetworkNodeGroupManipulationWithLabelInfoDTO> {
        return Requestor.run('/api/Network/{networkId}/group/{groupId}/group-update', 'POST', {networkId, groupId}, {}, info, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 networkId	networkId
     * @param {NodeGroupDisplayContentModel}		 nodeGroups	This parameter will be add in the body of the request
     * @return {Promise<Types.NetworkNodeGroupDisplayContentDTO>}		 A cancellable promise
     */
    networkDisplayGroupContent(networkId: number, nodeGroups: Types.NodeGroupDisplayContentModel, additionalOptions?: AdditionalOptions) : Promise<Types.NetworkNodeGroupDisplayContentDTO> {
        return Requestor.run('/api/Network/{networkId}/display-content', 'POST', {networkId}, {}, nodeGroups, additionalOptions);
    }


    /**
     * @async
     * @param {IdsMarkerListModel}		 markers	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @return {Promise<string>}		 A cancellable promise
     */
    networkInsertMarker(markers: Types.IdsMarkerListModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<string> {
        return Requestor.run('/api/Network/nodes/insert/marker', 'POST', {}, queryParameters, markers, additionalOptions);
    }


    /**
     * @async
     * @param {IdsMarkerListModel}		 markers	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @return {Promise<string>}		 A cancellable promise
     */
    networkDeleteMarkers(markers: Types.IdsMarkerListModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<string> {
        return Requestor.run('/api/Network/nodes/delete/marker', 'POST', {}, queryParameters, markers, additionalOptions);
    }


    /**
     * @async
     * @param {RelatedNetworkAlertsModel}		 explorationNetworkNodes	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 networkId	networkId
     * @param {integer=}		 scenarioId	scenarioId
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.RelatedNetworkDTO[]>}		 A cancellable promise
     */
    networkGetRelatedNetworksAndRatio(explorationNetworkNodes: Types.RelatedNetworkAlertsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.RelatedNetworkDTO[]> {
        return Requestor.run('/api/Network/related-networks-ratio', 'POST', {}, queryParameters, explorationNetworkNodes, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.SearchResultOfDocumentDTO>}		 A cancellable promise
     */
    networkGetDocuments(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfDocumentDTO> {
        return Requestor.run('/api/Network/{id}/documents', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {DocumentMetadataListModel}		 documentsMetadata	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfDocumentDTO>}		 A cancellable promise
     */
    networkCreateDocuments(id: number, documentsMetadata: Types.DocumentMetadataListModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfDocumentDTO> {
        return Requestor.run('/api/Network/{id}/documents', 'POST', {id}, {}, documentsMetadata, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkClaimsComputedView(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/{id}/claims/computed-view', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {NetworkElementsAndRootsModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkClaimsByElementIdsComputedView(model: Types.NetworkElementsAndRootsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/elements/claims/computed-view', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPersonsComputedView(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/{id}/persons/computed-view', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {NetworkElementsAndRootsModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPersonsByElementIdsComputedView(model: Types.NetworkElementsAndRootsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/elements/persons/computed-view', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkObjectsComputedView(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/{id}/objects/computed-view', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {NetworkElementsAndRootsModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkObjectsByElementIdsComputedView(model: Types.NetworkElementsAndRootsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/elements/objects/computed-view', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPoliciesComputedView(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/{id}/policies/computed-view', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {NetworkElementsAndRootsModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPoliciesByElementIdsComputedView(model: Types.NetworkElementsAndRootsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/elements/policies/computed-view', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkDevicesComputedView(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/{id}/devices/computed-view', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {NetworkElementsAndRootsModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkDevicesByElementIdsComputedView(model: Types.NetworkElementsAndRootsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/elements/devices/computed-view', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkBankAccountsComputedView(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/{id}/bankAccounts/computed-view', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {NetworkElementsAndRootsModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkBankAccountsByElementIdsComputedView(model: Types.NetworkElementsAndRootsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/elements/bankAccounts/computed-view', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkMailsComputedView(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/{id}/mails/computed-view', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {NetworkElementsAndRootsModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkMailsByElementIdsComputedView(model: Types.NetworkElementsAndRootsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/elements/mails/computed-view', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkAddressesComputedView(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/{id}/addresses/computed-view', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {NetworkElementsAndRootsModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkAddressesByElementIdsComputedView(model: Types.NetworkElementsAndRootsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/elements/addresses/computed-view', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPhoneNumbersComputedView(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/{id}/phoneNumbers/computed-view', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {NetworkElementsAndRootsModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPhoneNumbersByElementIdsComputedView(model: Types.NetworkElementsAndRootsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/elements/phoneNumbers/computed-view', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPolicyQuotesComputedView(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/{id}/policyQuotes/computed-view', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {NetworkElementsAndRootsModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkPolicyQuotesByElementIdsComputedView(model: Types.NetworkElementsAndRootsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/elements/policyQuotes/computed-view', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkProvidersComputedView(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/{id}/providers/computed-view', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {NetworkElementsAndRootsModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkProvidersByElementIdsComputedView(model: Types.NetworkElementsAndRootsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/elements/providers/computed-view', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkIntermediariesComputedView(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/{id}/intermediaries/computed-view', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {NetworkElementsAndRootsModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkIntermediariesByElementIdsComputedView(model: Types.NetworkElementsAndRootsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/elements/intermediaries/computed-view', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkNationalIdentifiersComputedView(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/{id}/nationalIdentifiers/computed-view', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {NetworkElementsAndRootsModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkNationalIdentifiersByElementIdsComputedView(model: Types.NetworkElementsAndRootsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/elements/nationalIdentifiers/computed-view', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkIntelligencesComputedView(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/{id}/intelligences/computed-view', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {NetworkElementsAndRootsModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO>}		 A cancellable promise
     */
    networkDetailsTableGetNetworkIntelligencesByElementIdsComputedView(model: Types.NetworkElementsAndRootsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfTupleOfListOfStringAndComputedTableRowDTO> {
        return Requestor.run('/api/NetworkDetailsTable/elements/intelligences/computed-view', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<string[]>}		 A cancellable promise
     */
    networkDetailsTableGetExportsList(additionalOptions?: AdditionalOptions) : Promise<string[]> {
        return Requestor.run('/api/NetworkDetailsTable/exports', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<string[]>}		 A cancellable promise
     */
    networkDetailsTableGetExportsListExploration(additionalOptions?: AdditionalOptions) : Promise<string[]> {
        return Requestor.run('/api/NetworkDetailsTable/exportsExploration', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 type	type
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.MultiCsvDTO>}		 A cancellable promise
     */
    networkDetailsTableExportNetworkElement(id: number, type: string, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.MultiCsvDTO> {
        return Requestor.run('/api/NetworkDetailsTable/{id}/export/{type}', 'POST', {id, type}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 type	type
     * @param {ExportNetworkElementsModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.MultiCsvDTO>}		 A cancellable promise
     */
    networkDetailsTableExportNetworkElementsByElementIds(type: string, model: Types.ExportNetworkElementsModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.MultiCsvDTO> {
        return Requestor.run('/api/NetworkDetailsTable/export/elements/{type}', 'POST', {type}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedNetworkExternalEntityCardDTO>}		 A cancellable promise
     */
    networkExternalEntityGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedNetworkExternalEntityCardDTO> {
        return Requestor.run('/api/NetworkExternalEntity/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<string>}		 A cancellable promise
     */
    networkExternalEntityGetExternalEntityDataSource(id: number, additionalOptions?: AdditionalOptions) : Promise<string> {
        return Requestor.run('/api/NetworkExternalEntity/{id}/get-external-data-source', 'GET', {id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedCardDTO>}		 A cancellable promise
     */
    networkNodeGroupGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedCardDTO> {
        return Requestor.run('/api/NetworkNodeGroup/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.NoteDTO[]>}		 A cancellable promise
     */
    noteGetByAlertId(alertId: number, additionalOptions?: AdditionalOptions) : Promise<Types.NoteDTO[]> {
        return Requestor.run('/api/Note/alert/{alertId}', 'GET', {alertId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<Types.NoteDTO>}		 A cancellable promise
     */
    noteGetById(id: number, additionalOptions?: AdditionalOptions) : Promise<Types.NoteDTO> {
        return Requestor.run('/api/Note/{id}', 'GET', {id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {NoteModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    noteCreate(model: Types.NoteModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Note/create', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {NoteModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    noteUpdateById(id: number, model: Types.NoteModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Note/{id}/update', 'PUT', {id}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 id	id
     * @param {string}		 pageType	pageType
     * @return {Promise<object>}		 A cancellable promise
     */
    noteDeleteNoteById(queryParameters?: object, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Note/delete', 'DELETE',{}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 noteId	noteId
     * @return {Promise<Types.EnumValueDTO[]>}		 A cancellable promise
     */
    noteGetPhysicalTherapyEquipmentWithCustomValuesByNoteId(noteId: number, additionalOptions?: AdditionalOptions) : Promise<Types.EnumValueDTO[]> {
        return Requestor.run('/api/Note/getPhysicalTherapyEquipmentWithCustomValuesByNoteId/{noteId}', 'GET', {noteId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.UnitaryPageDTO>}		 A cancellable promise
     */
    objectGetById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.UnitaryPageDTO> {
        return Requestor.run('/api/Object/{id}', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedCardDTO>}		 A cancellable promise
     */
    objectGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedCardDTO> {
        return Requestor.run('/api/Object/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {AlertCreationModalFieldModel}		 alertCreationModalFieldModel	This parameter will be add in the body of the request
     * @return {Promise<number>}		 A cancellable promise
     */
    objectCreateAlert(id: number, alertCreationModalFieldModel: Types.AlertCreationModalFieldModel, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Object/{id}/create-alert', 'POST', {id}, {}, alertCreationModalFieldModel, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 claimId	claimId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 pageType	pageType
     * @param {string}		 historyType	historyType
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ReconstructedComputedDTO>}		 A cancellable promise
     */
    objectGetByIdByClaimId(id: number, claimId: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ReconstructedComputedDTO> {
        return Requestor.run('/api/Object/{id}/claim/{claimId}', 'GET', {id, claimId}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.MultiCsvDTO>}		 A cancellable promise
     */
    objectExportHistory(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.MultiCsvDTO> {
        return Requestor.run('/api/Object/export/{id}/history', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 pageType	pageType
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @param {integer=}		 scenarioId	scenarioId
     * @return {Promise<Types.HistoryDTO>}		 A cancellable promise
     */
    objectGetHistory(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.HistoryDTO> {
        return Requestor.run('/api/Object/{id}/history', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.GeolocationDTO[]>}		 A cancellable promise
     */
    objectGetClaimsAddresses(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.GeolocationDTO[]> {
        return Requestor.run('/api/Object/{id}/claims/addresses', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfObjectDTO>}		 A cancellable promise
     */
    objectSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfObjectDTO> {
        return Requestor.run('/api/Object/search', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedTableRowDTO>}		 A cancellable promise
     */
    objectComputedViewSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedTableRowDTO> {
        return Requestor.run('/api/Object/search/computed-view', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 query	query
     * @param {integer=}		 start	start
     * @param {integer=}		 size	size
     * @return {Promise<Types.SearchResultOfObjectQuicksearchDTO>}		 A cancellable promise
     */
    objectQuickSearch(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfObjectQuicksearchDTO> {
        return Requestor.run('/api/Object/quicksearch', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.SearchResultOfDocumentDTO>}		 A cancellable promise
     */
    objectGetDocuments(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfDocumentDTO> {
        return Requestor.run('/api/Object/{id}/documents', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {DocumentMetadataListModel}		 documentsMetadata	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfDocumentDTO>}		 A cancellable promise
     */
    objectCreateDocuments(id: number, documentsMetadata: Types.DocumentMetadataListModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfDocumentDTO> {
        return Requestor.run('/api/Object/{id}/documents', 'POST', {id}, {}, documentsMetadata, additionalOptions);
    }


    /**
     * @async
     * @param {array=}		 models	models
     * @return {Promise<boolean[]>}		 A cancellable promise
     */
    permissionCan(models: Types.PermissionModel[], additionalOptions?: AdditionalOptions) : Promise<boolean[]> {
        return Requestor.run('/api/Permission/can', 'POST', {}, {}, models, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.UnitaryPageDTO>}		 A cancellable promise
     */
    personGetById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.UnitaryPageDTO> {
        return Requestor.run('/api/Person/{id}', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedCardDTO>}		 A cancellable promise
     */
    personGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedCardDTO> {
        return Requestor.run('/api/Person/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.RecoveryPayerDTO>}		 A cancellable promise
     */
    personGetPersonAsRecoveryPayerById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.RecoveryPayerDTO> {
        return Requestor.run('/api/Person/{id}/as-recovery-payer', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {AlertCreationModalFieldModel}		 alertCreationModalFieldModel	This parameter will be add in the body of the request
     * @return {Promise<number>}		 A cancellable promise
     */
    personCreateAlert(id: number, alertCreationModalFieldModel: Types.AlertCreationModalFieldModel, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Person/{id}/create-alert', 'POST', {id}, {}, alertCreationModalFieldModel, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 claimId	claimId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 pageType	pageType
     * @param {string}		 historyType	historyType
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ReconstructedComputedDTO>}		 A cancellable promise
     */
    personGetByIdByClaimId(id: number, claimId: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ReconstructedComputedDTO> {
        return Requestor.run('/api/Person/{id}/Claim/{claimId}', 'GET', {id, claimId}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.MultiCsvDTO>}		 A cancellable promise
     */
    personExportHistory(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.MultiCsvDTO> {
        return Requestor.run('/api/Person/export/{id}/history', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 pageType	pageType
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @param {integer=}		 scenarioId	scenarioId
     * @return {Promise<Types.HistoryDTO>}		 A cancellable promise
     */
    personGetHistory(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.HistoryDTO> {
        return Requestor.run('/api/Person/{id}/history', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.GeolocationDTO[]>}		 A cancellable promise
     */
    personGetClaimsAddresses(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.GeolocationDTO[]> {
        return Requestor.run('/api/Person/{id}/claims/addresses', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<Types.InsurerEntityDTO[]>}		 A cancellable promise
     */
    personGetInsurersContacts(additionalOptions?: AdditionalOptions) : Promise<Types.InsurerEntityDTO[]> {
        return Requestor.run('/api/Person/get-insurers-contacts', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<Types.ComputedTableRowDTO[]>}		 A cancellable promise
     */
    personGetComputedViewInsurersContacts(additionalOptions?: AdditionalOptions) : Promise<Types.ComputedTableRowDTO[]> {
        return Requestor.run('/api/Person/insurers-contacts/computed-view', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfPersonDTO>}		 A cancellable promise
     */
    personSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfPersonDTO> {
        return Requestor.run('/api/Person/search', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedTableRowDTO>}		 A cancellable promise
     */
    personComputedViewSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedTableRowDTO> {
        return Requestor.run('/api/Person/search/computed-view', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 query	query
     * @param {integer=}		 start	start
     * @param {integer=}		 size	size
     * @return {Promise<Types.SearchResultOfPersonQuicksearchDTO>}		 A cancellable promise
     */
    personQuickSearch(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfPersonQuicksearchDTO> {
        return Requestor.run('/api/Person/quicksearch', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.SearchResultOfDocumentDTO>}		 A cancellable promise
     */
    personGetDocuments(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfDocumentDTO> {
        return Requestor.run('/api/Person/{id}/documents', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {DocumentMetadataListModel}		 documentsMetadata	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfDocumentDTO>}		 A cancellable promise
     */
    personCreateDocuments(id: number, documentsMetadata: Types.DocumentMetadataListModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfDocumentDTO> {
        return Requestor.run('/api/Person/{id}/documents', 'POST', {id}, {}, documentsMetadata, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedCardDTO>}		 A cancellable promise
     */
    phoneNumberGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedCardDTO> {
        return Requestor.run('/api/PhoneNumber/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.UnitaryPageDTO>}		 A cancellable promise
     */
    policyGetById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.UnitaryPageDTO> {
        return Requestor.run('/api/Policy/{id}', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.RecoveryPayerDTO>}		 A cancellable promise
     */
    policyGetPolicyHolderAsRecoveryPayerById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.RecoveryPayerDTO> {
        return Requestor.run('/api/Policy/{id}/as-recovery-payer', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedCardDTO>}		 A cancellable promise
     */
    policyGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedCardDTO> {
        return Requestor.run('/api/Policy/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {AlertCreationModalFieldModel}		 alertCreationModalFieldModel	This parameter will be add in the body of the request
     * @return {Promise<number>}		 A cancellable promise
     */
    policyCreateAlert(id: number, alertCreationModalFieldModel: Types.AlertCreationModalFieldModel, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Policy/{id}/create-alert', 'POST', {id}, {}, alertCreationModalFieldModel, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.MultiCsvDTO>}		 A cancellable promise
     */
    policyExportHistory(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.MultiCsvDTO> {
        return Requestor.run('/api/Policy/export/{id}/history', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 pageType	pageType
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @param {integer=}		 scenarioId	scenarioId
     * @return {Promise<Types.HistoryDTO>}		 A cancellable promise
     */
    policyGetHistory(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.HistoryDTO> {
        return Requestor.run('/api/Policy/{id}/history', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.GeolocationDTO[]>}		 A cancellable promise
     */
    policyGetClaimsAddresses(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.GeolocationDTO[]> {
        return Requestor.run('/api/Policy/{id}/claims/addresses', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfPolicyDTO>}		 A cancellable promise
     */
    policySearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfPolicyDTO> {
        return Requestor.run('/api/Policy/search', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedTableRowDTO>}		 A cancellable promise
     */
    policyComputedViewSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedTableRowDTO> {
        return Requestor.run('/api/Policy/search/computed-view', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 query	query
     * @param {integer=}		 start	start
     * @param {integer=}		 size	size
     * @return {Promise<Types.SearchResultOfPolicyQuicksearchDTO>}		 A cancellable promise
     */
    policyQuickSearch(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfPolicyQuicksearchDTO> {
        return Requestor.run('/api/Policy/quicksearch', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.SearchResultOfDocumentDTO>}		 A cancellable promise
     */
    policyGetDocuments(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfDocumentDTO> {
        return Requestor.run('/api/Policy/{id}/documents', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {DocumentMetadataListModel}		 documentsMetadata	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfDocumentDTO>}		 A cancellable promise
     */
    policyCreateDocuments(id: number, documentsMetadata: Types.DocumentMetadataListModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfDocumentDTO> {
        return Requestor.run('/api/Policy/{id}/documents', 'POST', {id}, {}, documentsMetadata, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedCardDTO>}		 A cancellable promise
     */
    policyQuoteGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedCardDTO> {
        return Requestor.run('/api/PolicyQuote/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 key	key
     * @param {string}		 value	value
     * @return {Promise<object>}		 A cancellable promise
     */
    preferenceUpsertUnstructuredPreference(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Preference/upsertUnstructuredPreference', 'POST', {}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 preferenceId	preferenceId
     * @return {Promise<object>}		 A cancellable promise
     */
    preferenceDeletePreference(preferenceId: number, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Preference/delete/{preferenceId}', 'DELETE', {preferenceId}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 preferenceId	preferenceId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 newName	newName
     * @return {Promise<number>}		 A cancellable promise
     */
    preferenceRenamePreference(preferenceId: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Preference/rename/{preferenceId}', 'POST', {preferenceId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {PreferenceModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {boolean}		 isDefault	isDefault
     * @return {Promise<number>}		 A cancellable promise
     */
    preferenceUpsertStructuredPreference(model: Types.PreferenceModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Preference/upsertStructuredPreference', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 preferenceId	preferenceId
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {boolean}		 unset	unset
     * @return {Promise<number>}		 A cancellable promise
     */
    preferenceSetPreferenceAsDefault(preferenceId: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Preference/setAsDefault/{preferenceId}', 'POST', {preferenceId}, queryParameters, {}, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.UnitaryPageDTO>}		 A cancellable promise
     */
    providerGetById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.UnitaryPageDTO> {
        return Requestor.run('/api/Provider/{id}', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.ComputedCardDTO>}		 A cancellable promise
     */
    providerGetNetworkNodeById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.ComputedCardDTO> {
        return Requestor.run('/api/Provider/{id}/as-network-node', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.RecoveryPayerDTO>}		 A cancellable promise
     */
    providerGetProviderAsRecoveryPayerById(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.RecoveryPayerDTO> {
        return Requestor.run('/api/Provider/{id}/as-recovery-payer', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {AlertCreationModalFieldModel}		 alertCreationModalFieldModel	This parameter will be add in the body of the request
     * @return {Promise<number>}		 A cancellable promise
     */
    providerCreateAlert(id: number, alertCreationModalFieldModel: Types.AlertCreationModalFieldModel, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/Provider/{id}/create-alert', 'POST', {id}, {}, alertCreationModalFieldModel, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.GeolocationDTO[]>}		 A cancellable promise
     */
    providerGetClaimsAddresses(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.GeolocationDTO[]> {
        return Requestor.run('/api/Provider/{id}/claims/addresses', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @return {Promise<Types.MultiCsvDTO>}		 A cancellable promise
     */
    providerExportHistory(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.MultiCsvDTO> {
        return Requestor.run('/api/Provider/export/{id}/history', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 pageType	pageType
     * @param {string=}		 equalityContract	equalityContract
     * @param {integer=}		 year	year
     * @param {integer=}		 month	month
     * @param {integer=}		 day	day
     * @param {integer=}		 hour	hour
     * @param {integer=}		 minute	minute
     * @param {integer=}		 second	second
     * @param {integer=}		 millisecond	millisecond
     * @param {string=}		 offset	offset
     * @param {integer=}		 scenarioId	scenarioId
     * @return {Promise<Types.HistoryDTO>}		 A cancellable promise
     */
    providerGetHistory(id: number, model: Types.ParametersModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.HistoryDTO> {
        return Requestor.run('/api/Provider/{id}/history', 'POST', {id}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfProviderDTO>}		 A cancellable promise
     */
    providerSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfProviderDTO> {
        return Requestor.run('/api/Provider/search', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedTableRowDTO>}		 A cancellable promise
     */
    providerComputedViewSearch(model: Types.ParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedTableRowDTO> {
        return Requestor.run('/api/Provider/search/computed-view', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 query	query
     * @param {integer=}		 start	start
     * @param {integer=}		 size	size
     * @return {Promise<Types.SearchResultOfProviderQuicksearchDTO>}		 A cancellable promise
     */
    providerQuickSearch(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfProviderQuicksearchDTO> {
        return Requestor.run('/api/Provider/quicksearch', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<Types.SearchResultOfDocumentDTO>}		 A cancellable promise
     */
    providerGetDocuments(id: number, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfDocumentDTO> {
        return Requestor.run('/api/Provider/{id}/documents', 'GET', {id}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {DocumentMetadataListModel}		 documentsMetadata	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfDocumentDTO>}		 A cancellable promise
     */
    providerCreateDocuments(id: number, documentsMetadata: Types.DocumentMetadataListModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfDocumentDTO> {
        return Requestor.run('/api/Provider/{id}/documents', 'POST', {id}, {}, documentsMetadata, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<object>}		 A cancellable promise
     */
    purgedAlertGetReferenceCodes(additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/PurgedAlert/referencecodes', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<Types.AlertDTO>}		 A cancellable promise
     */
    purgedAlertGetById(id: number, additionalOptions?: AdditionalOptions) : Promise<Types.AlertDTO> {
        return Requestor.run('/api/PurgedAlert/{id}', 'GET', {id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {PurgedAlertModel}		 purgedAlertModel	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    purgedAlertSave(id: number, purgedAlertModel: Types.PurgedAlertModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/PurgedAlert/{id}', 'PUT', {id}, {}, purgedAlertModel, additionalOptions);
    }


    /**
     * @async
     * @param {IdListModel}		 ids	This parameter will be add in the body of the request
     * @return {Promise<Types.RecoveryDTO>}		 A cancellable promise
     */
    recoveryGetByClaimIds(ids: Types.IdListModel, additionalOptions?: AdditionalOptions) : Promise<Types.RecoveryDTO> {
        return Requestor.run('/api/Recovery/get-by-claim-ids', 'POST', {}, {}, ids, additionalOptions);
    }


    /**
     * @async
     * @param {RecoveriesModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfRecoveryDTO>}		 A cancellable promise
     */
    recoverySearch(model: Types.RecoveriesModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfRecoveryDTO> {
        return Requestor.run('/api/Recovery/search', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {RecoveriesModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedRecoveryTableRowDTO>}		 A cancellable promise
     */
    recoveryComputedViewSearch(model: Types.RecoveriesModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedRecoveryTableRowDTO> {
        return Requestor.run('/api/Recovery/search/computed-view', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {RecoveryCreateModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    recoveryCreate(model: Types.RecoveryCreateModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Recovery/create', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {RecoveryPayerCreateModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.RecoveryPayerDTO>}		 A cancellable promise
     */
    recoveryCreateRecoveryPayer(model: Types.RecoveryPayerCreateModel, additionalOptions?: AdditionalOptions) : Promise<Types.RecoveryPayerDTO> {
        return Requestor.run('/api/Recovery/create-recovery-payer', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {RecoveryCreateModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    recoveryUpdate(model: Types.RecoveryCreateModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Recovery/update', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<Types.RecoveryDTO>}		 A cancellable promise
     */
    recoveryGetById(id: number, additionalOptions?: AdditionalOptions) : Promise<Types.RecoveryDTO> {
        return Requestor.run('/api/Recovery/{id}', 'GET', {id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 id	id
     * @return {Promise<object>}		 A cancellable promise
     */
    recoveryDeleteById(queryParameters?: object, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Recovery/delete', 'DELETE',{}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {RecoveryUpdateLastClaimedDateModel}		 recoveryUpdateLastClaimedDateModel	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    recoveryHandleBulkUpdate(recoveryUpdateLastClaimedDateModel: Types.RecoveryUpdateLastClaimedDateModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Recovery/handleBulkUpdate/lastClaimedDateAndNumberOfRequests', 'POST', {}, {}, recoveryUpdateLastClaimedDateModel, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 exportName	exportName
     * @param {RecoveriesModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    recoveryTableCustomExport(exportName: string, model: Types.RecoveriesModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Recovery/tableCustomExport/{exportName}', 'POST', {exportName}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 role	role
     * @param {Object=}		 body	body
     * @return {Promise<Types.RecoveryDTO>}		 A cancellable promise
     */
    recoveryWorkflowHandleTransition(recoveryId: number, role: string, state: string, actionType: string, body: object, additionalOptions?: AdditionalOptions) : Promise<Types.RecoveryDTO> {
        return Requestor.run('/api/recovery/state/{recoveryId}/{role}/transition/{state}/{actionType}', 'POST', {recoveryId, role, state, actionType}, {}, body, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 role	role
     * @return {Promise<object>}		 A cancellable promise
     */
    recoveryWorkflowRevertTransition(recoveryId: number, role: string, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/recovery/state/{recoveryId}/{role}/revert', 'POST', {recoveryId, role}, {}, {}, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 role	role
     * @return {Promise<Types.WorkflowActionConfigurationDTO>}		 A cancellable promise
     */
    recoveryWorkflowActionConfiguration(role: string, actionType: string, additionalOptions?: AdditionalOptions) : Promise<Types.WorkflowActionConfigurationDTO> {
        return Requestor.run('/api/recovery/state/configuration/{role}/{actionType}', 'GET', {role, actionType}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {BulkTransitionModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    recoveryWorkflowHandleBulkTransition(model: Types.BulkTransitionModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/recovery/state/transition/bulk', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<Types.ReminderDTO[]>}		 A cancellable promise
     */
    reminderGetAllReminders(additionalOptions?: AdditionalOptions) : Promise<Types.ReminderDTO[]> {
        return Requestor.run('/api/Reminder', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {ReminderModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.UpdatedReminderDTO>}		 A cancellable promise
     */
    reminderAddReminder(model: Types.ReminderModel, additionalOptions?: AdditionalOptions) : Promise<Types.UpdatedReminderDTO> {
        return Requestor.run('/api/Reminder', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<Types.ReminderDTO>}		 A cancellable promise
     */
    reminderGetReminder(type: string, id: number, additionalOptions?: AdditionalOptions) : Promise<Types.ReminderDTO> {
        return Requestor.run('/api/Reminder/{type}/{id}', 'GET', {type, id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {ReminderModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.UpdatedReminderDTO>}		 A cancellable promise
     */
    reminderUpdateReminder(id: number, model: Types.ReminderModel, additionalOptions?: AdditionalOptions) : Promise<Types.UpdatedReminderDTO> {
        return Requestor.run('/api/Reminder/{id}', 'PUT', {id}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<object>}		 A cancellable promise
     */
    reminderRemoveReminder(id: number, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Reminder/{id}', 'DELETE', {id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {ReportGenerationModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 targetType	targetType
     * @param {string}		 targetId	targetId
     * @param {string}		 subTarget	subTarget
     * @param {string=}		 subTargetId	subTargetId
     * @return {Promise<number>}		 A cancellable promise
     */
    reportGenerationCaseManagementGenerate(model: Types.ReportGenerationModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/ReportGeneration/generate', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 pageId	pageId
     * @param {ReportGenerationModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @return {Promise<number>}		 A cancellable promise
     */
    reportGenerationGenerate(pageType: string, pageId: number, model: Types.ReportGenerationModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/ReportGeneration/generate/{pageType}/{pageId}', 'POST', {pageType, pageId}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {ReportGenerationModel}		 model	This parameter will be add in the body of the request
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {integer}		 alertId	alertId
     * @param {string}		 pageType	pageType
     * @param {integer}		 pageId	pageId
     * @param {boolean}		 documentMetadataStoredInDocumentService	documentMetadataStoredInDocumentService
     * @return {Promise<number>}		 A cancellable promise
     */
    reportGenerationGenerateReport(model: Types.ReportGenerationModel, queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<number> {
        return Requestor.run('/api/ReportGeneration', 'POST', {}, queryParameters, model, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 dashboard	dashboard
     * @return {Promise<Types.EncryptedClaimsDTO>}		 A cancellable promise
     */
    reportingGetEncryptedClaims(dashboard: string, additionalOptions?: AdditionalOptions) : Promise<Types.EncryptedClaimsDTO> {
        return Requestor.run('/api/Reporting/encrypted-claims/{dashboard}', 'GET', {dashboard}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<string>}		 A cancellable promise
     */
    resourceGetLastImportDate(additionalOptions?: AdditionalOptions) : Promise<string> {
        return Requestor.run('/lastimportdate', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {ComplianceQuestionsModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfClaimDTO>}		 A cancellable promise
     */
    restrictedClaimSearch(model: Types.ComplianceQuestionsModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfClaimDTO> {
        return Requestor.run('/api/Restricted/ClaimSearch', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ComplianceQuestionsModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedTableRowDTO>}		 A cancellable promise
     */
    restrictedClaimComputedViewSearch(model: Types.ComplianceQuestionsModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedTableRowDTO> {
        return Requestor.run('/api/Restricted/ClaimComputedViewSearch', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ComplianceQuestionsModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfIntermediaryDTO>}		 A cancellable promise
     */
    restrictedIntermediarySearch(model: Types.ComplianceQuestionsModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfIntermediaryDTO> {
        return Requestor.run('/api/Restricted/IntermediarySearch', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ComplianceQuestionsModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedTableRowDTO>}		 A cancellable promise
     */
    restrictedIntermediaryComputedViewSearch(model: Types.ComplianceQuestionsModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedTableRowDTO> {
        return Requestor.run('/api/Restricted/IntermediaryComputedViewSearch', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ComplianceQuestionsModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfNetworkDTO>}		 A cancellable promise
     */
    restrictedNetworkSearch(model: Types.ComplianceQuestionsModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfNetworkDTO> {
        return Requestor.run('/api/Restricted/NetworkSearch', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ComplianceQuestionsModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfObjectDTO>}		 A cancellable promise
     */
    restrictedObjectSearch(model: Types.ComplianceQuestionsModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfObjectDTO> {
        return Requestor.run('/api/Restricted/ObjectSearch', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ComplianceQuestionsModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedTableRowDTO>}		 A cancellable promise
     */
    restrictedObjectComputedViewSearch(model: Types.ComplianceQuestionsModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedTableRowDTO> {
        return Requestor.run('/api/Restricted/ObjectComputedViewSearch', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ComplianceQuestionsModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfPersonDTO>}		 A cancellable promise
     */
    restrictedPersonSearch(model: Types.ComplianceQuestionsModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfPersonDTO> {
        return Requestor.run('/api/Restricted/PersonSearch', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ComplianceQuestionsModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedTableRowDTO>}		 A cancellable promise
     */
    restrictedPersonComputedViewSearch(model: Types.ComplianceQuestionsModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedTableRowDTO> {
        return Requestor.run('/api/Restricted/PersonComputedViewSearch', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ComplianceQuestionsModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfPolicyDTO>}		 A cancellable promise
     */
    restrictedPolicySearch(model: Types.ComplianceQuestionsModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfPolicyDTO> {
        return Requestor.run('/api/Restricted/PolicySearch', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ComplianceQuestionsModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedTableRowDTO>}		 A cancellable promise
     */
    restrictedPolicyComputedViewSearch(model: Types.ComplianceQuestionsModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedTableRowDTO> {
        return Requestor.run('/api/Restricted/PolicyComputedViewSearch', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ComplianceQuestionsModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfProviderDTO>}		 A cancellable promise
     */
    restrictedProviderSearch(model: Types.ComplianceQuestionsModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfProviderDTO> {
        return Requestor.run('/api/Restricted/ProviderSearch', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {ComplianceQuestionsModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedTableRowDTO>}		 A cancellable promise
     */
    restrictedProviderComputedViewSearch(model: Types.ComplianceQuestionsModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedTableRowDTO> {
        return Requestor.run('/api/Restricted/ProviderComputedViewSearch', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {SmartDocumentParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfSmartDocumentDTO>}		 A cancellable promise
     */
    smartDocumentGetSearch(model: Types.SmartDocumentParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfSmartDocumentDTO> {
        return Requestor.run('/api/SmartDocument/search', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {SmartDocumentParametersModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<Types.SearchResultOfComputedSmartDocumentTableRowDTO>}		 A cancellable promise
     */
    smartDocumentGetComputedViewSearch(model: Types.SmartDocumentParametersModel, additionalOptions?: AdditionalOptions) : Promise<Types.SearchResultOfComputedSmartDocumentTableRowDTO> {
        return Requestor.run('/api/SmartDocument/search/computed-view', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {SmartDocumentModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    smartDocumentCreate(model: Types.SmartDocumentModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/SmartDocument', 'POST', {}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @param {SmartDocumentModel}		 model	This parameter will be add in the body of the request
     * @return {Promise<object>}		 A cancellable promise
     */
    smartDocumentUpdate(id: number, model: Types.SmartDocumentModel, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/SmartDocument/{id}', 'PUT', {id}, {}, model, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<object>}		 A cancellable promise
     */
    smartDocumentDelete(id: number, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/SmartDocument/{id}', 'DELETE', {id}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @return {Promise<Types.TraceabilityLogsDTO[]>}		 A cancellable promise
     */
    traceabilityLogsGetTraceabilityLogsStatistics(additionalOptions?: AdditionalOptions) : Promise<Types.TraceabilityLogsDTO[]> {
        return Requestor.run('/api/TraceabilityLogs/statistics', 'GET', {}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {string}		 fromDate	fromDate
     * @return {Promise<Types.TraceabilityLogsDTO[]>}		 A cancellable promise
     */
    traceabilityLogsGetTraceabilityLogsStatisticsFromDate(fromDate: string, additionalOptions?: AdditionalOptions) : Promise<Types.TraceabilityLogsDTO[]> {
        return Requestor.run('/api/TraceabilityLogs/statistics/{fromDate}', 'GET', {fromDate}, {}, null, additionalOptions);
    }


    /**
     * @async
     * @param {Object}		 queryParameters	This parameter will be add in the url of the request
     * @param {string}		 culture	culture
     * @return {Promise<object>}		 A cancellable promise
     */
    translationGetByCulture(queryParameters?: Record<string, any>, additionalOptions?: AdditionalOptions) : Promise<object> {
        return Requestor.run('/api/Translation', 'GET', {}, queryParameters, null, additionalOptions);
    }


    /**
     * @async
     * @param {integer}		 id	id
     * @return {Promise<Types.RecoveryPayerDTO>}		 A cancellable promise
     */
    userCreatedGetUserCreatedAsRecoveryPayerById(id: number, additionalOptions?: AdditionalOptions) : Promise<Types.RecoveryPayerDTO> {
        return Requestor.run('/api/UserCreated/{id}/as-recovery-payer', 'GET', {id}, {}, null, additionalOptions);
    }
}

const api = new API();

/* eslint-enable */
export default api;
