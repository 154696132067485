import counterpart from "counterpart";
import moment from "moment";
import numbro from "numbro";
import Promise from "bluebird";

import configuration from "@app/configuration";
import API from "@api";
import PrimaryLanguages from "~/locales/primary";
import ExtendedLanguages from "~/locales/extended";

const shiftDefaultLanguage = window.shiftContext.languages.default;
const missingTranslations = {};

const setLocaleEnv = (lang, data) => {
    data.counterpart = {
        counterpart: {
            pluralize: (entry, count) => entry[count === 1 ? "one" : "other"],
        },
    };
    // Set translations
    counterpart.registerTranslations(lang, data.counterpart);
    counterpart.registerTranslations(lang, data.messages);
    counterpart.registerTranslations(lang, data.miscellaneous);
    // Set datetime locale settings
    counterpart.setLocale(lang);
    // Don't display "missing translation"
    counterpart.setMissingEntryGenerator((key) => {
        // eslint-disable-next-line no-undef
        if (!SILENT_DICTIONARY && !missingTranslations[key]) {
            console.warn(`Missing translation for: ${key}`);
            missingTranslations[key] = true;
        }
        return key.split(".").pop();
    });
    moment.locale(lang);
    // Set our humanizer locale
    if (data.humanize_duration) {
        configuration.humanizerLanguage = data.humanize_duration.language;
    }
    // Set currencies
    if (data.numbro) {
        numbro.registerLanguage({
            ...data.numbro,
            ordinal: () => "",
            currency: {},
        });
        numbro.setLanguage(data.numbro.languageTag);
    }
};

const saveLanguageToLocalStorage = (storageKey, translations) => {
    window.localStorage.setItem(
        storageKey,
        JSON.stringify({
            revision: window.shiftContext.revision,
            translations: translations,
        })
    );
};

export const registerLanguages = () => {
    window.shiftPrimaryLanguageMap = {};
    window.shiftExtendedLanguageMap = {};

    for (const primaryLanguage of PrimaryLanguages) {
        window.shiftPrimaryLanguageMap[primaryLanguage] = () => import(`~/locales/primary/${primaryLanguage}.json`);
    }

    for (const extendedLanguage of ExtendedLanguages) {
        window.shiftExtendedLanguageMap[extendedLanguage] = () => import(`~/locales/extended/${extendedLanguage}.json`);
    }
};

const loadLanguageServerIfNecessary = (culture) => {
    // If we are developing locally, translations may change without the revision changing
    if (process.env.NODE_ENV === "production") {
        const storageKey = `translations-${culture}`;
        try {
            const stored = JSON.parse(window.localStorage.getItem(storageKey));
            // Translations are committed in git, so as long as we are on the same revision, they shouldn't have changed
            if (stored && stored.revision === window.shiftContext.revision) {
                return Promise.resolve(stored.translations);
            }
        } catch {
            // Swallowing the error is fine here. If we failed to load cached data, we'll call the API then reset the cache
        }

        return fetch(`/${culture}.json`)
            .then((response) => {
                if (!response.ok) {
                    throw Error(`Missing translation file /${culture}.json`);
                }
                return response.json();
            })
            .catch(() => API.translationGetByCulture({ culture }))
            .tap((resp) => {
                try {
                    saveLanguageToLocalStorage(storageKey, resp);
                } catch (e) {
                    if (!(e.name === "QuotaExceededError")) {
                        throw e;
                    }

                    // if localStorage is full, clear out the previous translations
                    Object.keys(window.localStorage).forEach((key) => {
                        if (!key.startsWith("translations-")) {
                            return;
                        }
                        window.localStorage.removeItem(key);
                    });

                    // try to save translations again
                    saveLanguageToLocalStorage(storageKey, resp);
                }
            });
    }

    return fetch(`/${culture}.json`)
        .then((response) => {
            if (!response.ok) {
                throw Error(`Missing translation file /${culture}.json`);
            }
            return response.json();
        })
        .catch(() => API.translationGetByCulture({ culture }));
};

export const loadLanguageServer = (culture) =>
    loadLanguageServerIfNecessary(culture).then((resp) => {
        counterpart.registerTranslations(culture, { detection: resp });
        return resp;
    });

export const loadLanguageUI = async (language) => {
    // First, try to identify the right language to fetch, with fallback on default language if not foudn
    const primary = language.split("-")[0];

    let fetchLanguage = window.shiftPrimaryLanguageMap[primary] ? language : shiftDefaultLanguage;
    fetchLanguage = window.shiftExtendedLanguageMap[fetchLanguage] ? fetchLanguage : shiftDefaultLanguage;

    // Second, load the translations from Primary to Extended (so that we "inherit" translations from the parant language)
    const fetchLanguageSplit = fetchLanguage.split("-");
    // this is the typical case (ex.: 'fr-FR')
    if (fetchLanguageSplit.length === 2) {
        const fetchPrimary = fetchLanguageSplit[0];
        const primaryLanguage = await window.shiftPrimaryLanguageMap[fetchPrimary]();
        const extLanguage = await window.shiftExtendedLanguageMap[fetchLanguage]();

        setLocaleEnv(fetchLanguage, { ...primaryLanguage });
        setLocaleEnv(fetchLanguage, { ...extLanguage });

        return extLanguage.flag;
    }

    // for clients with a specific language like 'fr-FR-CLIENT', this logic allows us to load 'fr', 'fr-FR' and 'fr-FR-CLIENT' translations
    const fetchPrimary = fetchLanguageSplit[0];
    const fetchExtended = `${fetchLanguageSplit[0]}-${fetchLanguageSplit[1]}`;

    const primaryLanguage = await window.shiftPrimaryLanguageMap[fetchPrimary]();
    const extLanguage = await window.shiftExtendedLanguageMap[fetchExtended]();
    const clientExtLanguage = await window.shiftExtendedLanguageMap[fetchLanguage]();

    setLocaleEnv(fetchLanguage, { ...primaryLanguage });
    // check that the extendend language exists (ex.: if we have a 'fr-ZZ-CLIENT', we check that 'fr-ZZ' exists before using it to set the translations!)
    if (extLanguage) {
        setLocaleEnv(fetchLanguage, { ...extLanguage });
    }
    setLocaleEnv(fetchLanguage, { ...clientExtLanguage });

    return extLanguage.flag;
};

export default {
    registerLanguages,
    loadLanguageServer,
    loadLanguageUI,
};
