// General config information will go in this file
// Libraries

// We need to shim React to handle errors better
import moment from "moment";

// Define date locale configurations
const englishMomentConfig = {
    parentLocale: "en",
    months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
    monthsShort: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"),
    weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
    weekdaysShort: "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"),
    weekdaysMin: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"),
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "DD/MM/YYYY LT",
        LLLL: "dddd D MMMM YYYY LT",
    },
};

const frenchMomentConfig = {
    months: "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split("_"),
    monthsShort: "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split("_"),
    weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
    weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
    weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "DD/MM/YYYY LT",
        LLLL: "dddd D MMMM YYYY LT",
    },
    calendar: {
        sameDay: "[Aujourd'hui à] LT",
        nextDay: "[Demain à] LT",
        nextWeek: "dddd [à] LT",
        lastDay: "[Hier à] LT",
        lastWeek: "dddd [dernier à] LT",
        sameElse: "L",
    },
    relativeTime: {
        future: "dans %s",
        past: "il y a %s",
        s: "quelques secondes",
        m: "une minute",
        mm: "%d minutes",
        h: "une heure",
        hh: "%d heures",
        d: "un jour",
        dd: "%d jours",
        M: "un mois",
        MM: "%d mois",
        y: "une année",
        yy: "%d années",
    },
    ordinalParse: /\d{1,2}(er|ème)/,
    ordinal(number) {
        return number + (number === 1 ? "er" : "ème");
    },
    meridiemParse: /PD|MD/,
    isPM(input) {
        return input.charAt(0) === "M";
    },
    // in case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example)
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */
    // },
    meridiem(hours) {
        return hours < 12 ? "PD" : "MD";
    },
    // Monday is the first day of the week.
    // The week that contains Jan 4th is the first week of the year.
    week: {
        dow: 1,
        doy: 4,
    },
};

const dutchMomentConfig = {
    months: "januari_februari_maart_april_mei_juni_juli_augustus_september_oktober_november_december".split("_"),
    monthsShort: "jan._feb._mrt._apr._mei_juni_juli_aug._sep._okt._nov._dec.".split("_"),
    weekdays: "zondag_maandag_dinsdag_woensdag_donderdag_vrijdag_zaterdag".split("_"),
    weekdaysShort: "zo._ma._di._wo._do._vr._za.".split("_"),
    weekdaysMin: "Zo_Ma_Di_Wo_Do_Vr_Za".split("_"),
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "DD/MM/YYYY LT",
        LLLL: "dddd D MMMM YYYY LT",
    },
    calendar: {
        sameDay: "[Vandaag om] LT",
        nextDay: "[Morgen om] LT",
        nextWeek: "dddd [om] LT",
        lastDay: "[Gisteren om] LT",
        lastWeek: "[Afgelopen] dddd [om] LT",
        sameElse: "L",
    },
    relativeTime: {
        future: "in %s",
        past: "%s geleden",
        s: "enkele seconden",
        m: "een minuut",
        mm: "%d minuten",
        h: "een uur",
        hh: "%d uur",
        d: "een dag",
        dd: "%d dagen",
        M: "een maand",
        MM: "%d maanden",
        y: "een jaar",
        yy: "%d jaar",
    },
    ordinalParse: /\d{1,2}(ste|de)/,
    ordinal(number) {
        return number + (number === 1 ? "ste" : "de");
    },
    meridiemParse: /PD|MD/,
    isPM(input) {
        return input.charAt(0) === "M";
    },
    // in case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example)
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */
    // },
    meridiem(hours) {
        return hours < 12 ? "PD" : "MD";
    },
    // Monday is the first day of the week.
    // The week that contains Jan 4th is the first week of the year.
    week: {
        dow: 1,
        doy: 4,
    },
};

const spanishMomentConfig = {
    months: "enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre".split("_"),
    monthsShort: "ene._febr._mar._abr._may._jun._jul._ago._sept._oct._nov._dic.".split("_"),
    weekdays: "domingo_lunes_martes_miércoles_jueves_viernes_sábado".split("_"),
    weekdaysShort: "dom._lun._mar._miér._jue._vier._sáb.".split("_"),
    weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sa".split("_"),
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "DD/MM/YYYY LT",
        LLLL: "dddd D MMMM YYYY LT",
    },
    calendar: {
        sameDay: "[Hoy a las] LT",
        nextDay: "[Mañana a las] LT",
        nextWeek: "dddd [a las] LT",
        lastDay: "[Ayer a las] LT",
        lastWeek: "dddd [pasado a las] LT",
        sameElse: "L",
    },
    relativeTime: {
        future: "en %s",
        past: "hace %s",
        s: "algunos segundos",
        m: "un minuto",
        mm: "%d minutos",
        h: "una hora",
        hh: "%d horas",
        d: "un día",
        dd: "%d días",
        M: "un mes",
        MM: "%d meses",
        y: "un año",
        yy: "%d años",
    },
    ordinalParse: /\d{1,2}(º|º)/,
    ordinal(number) {
        return number + (number === 1 ? "º" : "º");
    },
    meridiemParse: /PD|MD/,
    isPM(input) {
        return input.charAt(0) === "M";
    },
    // in case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example)
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */
    // },
    meridiem(hours) {
        return hours < 12 ? "PD" : "MD";
    },
    // Monday is the first day of the week.
    // The week that contains Jan 4th is the first week of the year.
    week: {
        dow: 1,
        doy: 4,
    },
};

const germanMomentConfig = {
    months: "Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember".split("_"),
    monthsShort: "Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sept._Okt._Nov._Dez.".split("_"),
    weekdays: "Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag".split("_"),
    weekdaysShort: "So._Mo._Di._Mi._Do._Fr._Sa.".split("_"),
    weekdaysMin: "So_Mo_Di_Mi_Do_Fr_Sa".split("_"),
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "YYYY-MM-DD",
        LL: "D MMMM YYYY",
        LLL: "YYYY-MM-DD LT",
        LLLL: "dddd D MMMM YYYY LT",
    },
    calendar: {
        sameDay: "[Heute um] LT",
        nextDay: "[Morgen um] LT",
        nextWeek: "dddd [um] LT",
        lastDay: "[Gestern um] LT",
        lastWeek: "dddd [zuletzt um] LT",
        sameElse: "L",
    },
    relativeTime: {
        future: "in %s",
        past: "vor %s",
        s: "einigen Sekunden",
        m: "eine Minute",
        mm: "%d Minuten",
        h: "eine Stunde",
        hh: "%d Studen",
        d: "ein Tag",
        dd: "%d Tag",
        M: "ein Monat",
        MM: "%d Monaten",
        y: "ein Jahr",
        yy: "%d Jahren",
    },
    ordinalParse: /\d{1,2}(er|th)/,
    ordinal(number) {
        return `${number}te`;
    },
    meridiemParse: /PD|MD/,
    isPM(input) {
        return input.charAt(0) === "M";
    },
    // in case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example)
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */
    // },
    meridiem(hours) {
        return hours < 12 ? "PD" : "MD";
    },
    // Monday is the first day of the week.
    // The week that contains Jan 4th is the first week of the year.
    week: {
        dow: 1,
        doy: 4,
    },
};

const danishMomentConfig = {
    months: "Januar_Februar_Marts_April_Maj_Juni_Juli_August_September_Oktober_November_December".split("_"),
    monthsShort: "Jan._Feb._Mar_Apr._Maj_Juni_Juli._Aug_Sept._Okt._Nov._Dec.".split("_"),
    weekdays: "Søndag_Mandag_Tirsdag_Onsdag_Torsdag_Fredag_Lørdag".split("_"),
    weekdaysShort: "Søn._Man._Tir._Ons._Tor._Fre._Lør.".split("_"),
    weekdaysMin: "Sø_Ma_Ti_On_To_Fr_Lø".split("_"),
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "DD/MM/YYYY LT",
        LLLL: "dddd D MMMM YYYY LT",
    },
    calendar: {
        sameDay: "[I dag kl.] LT",
        nextDay: "[I morgen kl.] LT",
        nextWeek: "[kommende] dddd [kl.] LT",
        lastDay: "[I går kl.] LT",
        lastWeek: "[Sidste] dddd [kl.] LT",
        sameElse: "L",
    },
    relativeTime: {
        future: "om %s",
        past: "for %s siden",
        s: "få sekunder",
        m: "et minut",
        mm: "%d minutter",
        h: "en time",
        hh: "%d timer",
        d: "en dag",
        dd: "%d dage",
        M: "en måned",
        MM: "%d måneder",
        y: "et år",
        yy: "%d år",
    },
    ordinalParse: /\d{1,2}(er|th)/,
    ordinal(number) {
        return `${number}te`;
    },
    meridiemParse: /PD|MD/,
    isPM(input) {
        return input.charAt(0) === "M";
    },
    // in case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example)
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */
    // },
    meridiem(hours) {
        return hours < 12 ? "PD" : "MD";
    },
    // Monday is the first day of the week.
    // The week that contains Jan 4th is the first week of the year.
    week: {
        dow: 1,
        doy: 4,
    },
};

const japaneseMomentConfig = {
    months: "1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月".split("_"),
    monthsShort: "1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月".split("_"),
    weekdays: "日曜日_月曜日_火曜日_水曜日_木曜日_金曜日_土曜日".split("_"),
    weekdaysShort: "日_月_火_水_木_金_土".split("_"),
    weekdaysMin: "日_月_火_水_木_金_土".split("_"),
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "YYYY/MM/DD",
        LL: "YYYY年M月D日",
        LLL: "YYYY年M月D日 HH:mm",
        LLLL: "YYYY年M月D日 HH:mm dddd",
        l: "YYYY/MM/DD",
        ll: "YYYY年M月D日",
        lll: "YYYY年M月D日 HH:mm",
        llll: "YYYY年M月D日 HH:mm dddd",
    },
    meridiemParse: /午前|午後/i,
    isPM: (input) => input === "午後",
    meridiem: (hour) => {
        if (hour < 12) {
            return "午前";
        }
        return "午後";
    },
    calendar: {
        sameDay: "[今日] LT",
        nextDay: "[明日] LT",
        nextWeek: "[来週]dddd LT",
        lastDay: "[昨日] LT",
        lastWeek: "[前週]dddd LT",
        sameElse: "L",
    },
    dayOfMonthOrdinalParse: /\d{1,2}日/,
    ordinal: (number, period) => {
        switch (period) {
            case "d":
            case "D":
            case "DDD":
                return `${number}日`;
            default:
                return number;
        }
    },
    relativeTime: {
        future: "%s後",
        past: "%s前",
        s: "数秒",
        m: "1分",
        mm: "%d分",
        h: "1時間",
        hh: "%d時間",
        d: "1日",
        dd: "%d日",
        M: "1ヶ月",
        MM: "%dヶ月",
        y: "1年",
        yy: "%d年",
    },
};

const italianMomentConfig = {
    months: "gennaio_febbraio_marzo_aprile_maggio_giugno_luglio_agosto_settembre_ottobre_novembre_dicembre".split("_"),
    monthsShort: "gen._feb._mar._apr._mag._giu._lug._ago._set._ott._nov._dic.".split("_"),
    weekdays: "domenica_lunedì_martedì_mercoledì_giovedì_venerdì_sabato".split("_"),
    weekdaysShort: "dom._lun._mar._mer._gio._ven._sab.".split("_"),
    weekdaysMin: "Do_Lu_Ma_Me_Gi_Ve_Sa".split("_"),
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "DD/MM/YYYY LT",
        LLLL: "dddd D MMMM YYYY LT",
    },
    calendar: {
        sameDay: "[Oggi alle] LT",
        nextDay: "[Domani alle] LT",
        nextWeek: "dddd [alle] LT",
        lastDay: "[Ieri alle] LT",
        lastWeek: "dddd [scorsa alle] LT",
        sameElse: "L",
    },
    relativeTime: {
        future: "Tra %s",
        past: "%s fa",
        s: "qualche secondo",
        m: "un minuto",
        mm: "%d minuti",
        h: "un'ora",
        hh: "%d ore",
        d: "un giorno",
        dd: "%d giorni",
        M: "un mese",
        MM: "%d mesi",
        y: "un anno",
        yy: "%d anni",
    },
    ordinalParse: /\d{1,2}(o)/,
    ordinal(number) {
        return `${number}o`;
    },
    meridiemParse: /PD|MD/,
    isPM(input) {
        return input.charAt(0) === "M";
    },
    // in case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example)
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */
    // },
    meridiem(hours) {
        return hours < 12 ? "PD" : "MD";
    },
    // Monday is the first day of the week.
    // The week that contains Jan 4th is the first week of the year.
    week: {
        dow: 1,
        doy: 4,
    },
};

const portugueseMomentConfig = {
    months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split("_"),
    monthsShort: "jan._fev._mar._abr._mai._jun._jul._ago._set._out._nov._dez.".split("_"),
    weekdays: "domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split("_"),
    weekdaysShort: "dom._seg._ter._qua._qui._sex._sáb.".split("_"),
    weekdaysMin: "Do_Seg_Te_Qua_Qui_Sex_Sa".split("_"),
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "DD/MM/YYYY LT",
        LLLL: "dddd D MMMM YYYY LT",
    },
    calendar: {
        sameDay: "[Hoje às] LT",
        nextDay: "[Amanhã às] LT",
        nextWeek: "dddd [às] LT",
        lastDay: "[Ontem às] LT",
        lastWeek: "dddd [passada às] LT",
        sameElse: "L",
    },
    relativeTime: {
        future: "em %s",
        past: "há %s",
        s: "alguns segundos",
        m: "um minuto",
        mm: "%d minutos",
        h: "uma hora",
        hh: "%d horas",
        d: "um dia",
        dd: "%d dias",
        M: "um mês",
        MM: "%d meses",
        y: "um ano",
        yy: "%d anos",
    },
    ordinalParse: /\d{1,2}(º|º)/,
    ordinal(number) {
        return number + (number === 1 ? "º" : "º");
    },
    meridiemParse: /PD|MD/,
    isPM(input) {
        return input.charAt(0) === "M";
    },
    // in case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example)
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */
    // },
    meridiem(hours) {
        return hours < 12 ? "PD" : "MD";
    },
    // Monday is the first day of the week.
    // The week that contains Jan 4th is the first week of the year.
    week: {
        dow: 1,
        doy: 4,
    },
};

// Set up locales for the our different display formats
moment.updateLocale("en", englishMomentConfig);
moment.locale("da", danishMomentConfig);
moment.locale("fr", frenchMomentConfig);
moment.locale("nl", dutchMomentConfig);
moment.locale("es", spanishMomentConfig);
moment.locale("de", germanMomentConfig);
moment.locale("ja", japaneseMomentConfig);
moment.locale("it", italianMomentConfig);
moment.locale("pt", portugueseMomentConfig);

moment.defineLocale("en-US", {
    parentLocale: "en",
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "MM/DD/YYYY",
        LL: "MMMM Do YYYY",
        LLL: "MM/DD/YYYY LT",
        LLLL: "dddd, MMMM Do, YYYY LT",
    },
});

moment.defineLocale("en-US-USAA", {
    parentLocale: "en",
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "MM/DD/YYYY",
        LL: "MMMM Do YYYY",
        LLL: "MM/DD/YYYY LT",
        LLLL: "dddd, MMMM Do, YYYY LT",
    },
});

moment.defineLocale("en-US-ELEPHANT", {
    parentLocale: "en-US",
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "MM/DD/YYYY",
        LL: "MMMM Do YYYY",
        LLL: "MM/DD/YYYY LT",
        LLLL: "dddd, MMMM Do, YYYY LT",
    },
});

moment.defineLocale("en-US-Geico", {
    parentLocale: "en-US",
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "MM/DD/YYYY",
        LL: "MMMM Do YYYY",
        LLL: "MM/DD/YYYY LT",
        LLLL: "dddd, MMMM Do, YYYY LT",
    },
});

moment.defineLocale("en-US-Liberty", {
    parentLocale: "en-US",
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "MM/DD/YYYY",
        LL: "MMMM Do YYYY",
        LLL: "MM/DD/YYYY LT",
        LLLL: "dddd, MMMM Do, YYYY LT",
    },
});

moment.defineLocale("en-US-STFM", {
    parentLocale: "en-US",
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "MM/DD/YYYY",
        LL: "MMMM Do YYYY",
        LLL: "MM/DD/YYYY LT",
        LLLL: "dddd, MMMM Do, YYYY LT",
    },
});

moment.defineLocale("en-US-Subrogation", {
    parentLocale: "en-US",
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "MM/DD/YYYY",
        LL: "MMMM Do YYYY",
        LLL: "MM/DD/YYYY LT",
        LLLL: "dddd, MMMM Do, YYYY LT",
    },
});

moment.defineLocale("en-US-ALLI", {
    parentLocale: "en-US",
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "MM/DD/YYYY",
        LL: "MMMM Do YYYY",
        LLL: "MM/DD/YYYY LT",
        LLLL: "dddd, MMMM Do, YYYY LT",
    },
});

moment.defineLocale("en-US-WFLD", {
    parentLocale: "en-US",
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "MM/DD/YYYY",
        LL: "MMMM Do YYYY",
        LLL: "MM/DD/YYYY LT",
        LLLL: "dddd, MMMM Do, YYYY LT",
    },
});

moment.defineLocale("en-US-DemoPnC", {
    parentLocale: "en-US",
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "MM/DD/YYYY",
        LL: "MMMM Do YYYY",
        LLL: "MM/DD/YYYY LT",
        LLLL: "dddd, MMMM Do, YYYY LT",
    },
});

moment.defineLocale("en-CA", {
    parentLocale: "en",
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "MM/DD/YYYY",
        LL: "MMMM Do YYYY",
        LLL: "MM/DD/YYYY LT",
        LLLL: "dddd, MMMM Do, YYYY LT",
    },
});

moment.defineLocale("en-CA-PnC", {
    parentLocale: "en",
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "YYYY-MM-DD",
        LL: "YYYY MMMM Do",
        LLL: "YYYY-MM-DD LT",
        LLLL: "YYYY, MMMM Do, dddd LT",
    },
});

moment.defineLocale("fr-CA", {
    parentLocale: "fr",
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "MM/DD/YYYY",
        LL: "MMMM Do YYYY",
        LLL: "MM/DD/YYYY LT",
        LLLL: "dddd, MMMM Do, YYYY LT",
    },
});

// Danish
moment.defineLocale("da-DK", { parentLocale: "da" });

// German
moment.defineLocale("de-DE", { parentLocale: "de" });
moment.defineLocale("de-CH", {
    parentLocale: "de",
    longDateFormat: {
        L: "DD.MM.YYYY",
        LLL: "DD.MM.YYYY LT",
    },
});
moment.defineLocale("de-DemoPnC", {
    parentLocale: "de",
    longDateFormat: {
        L: "DD.MM.YYYY",
        LLL: "DD.MM.YYYY LT",
    },
});
moment.defineLocale("de-CH-AxaSwitzerland", { parentLocale: "de-CH" });
moment.defineLocale("de-CH-Generali", { parentLocale: "de-CH" });

// English
moment.defineLocale("en-GB", { parentLocale: "en" });
moment.defineLocale("en-CA-PnC", { parentLocale: "en-CA" });
moment.defineLocale("en-HK", { parentLocale: "en" });
moment.defineLocale("en-ID", { parentLocale: "en" });
moment.defineLocale("en-IN", { parentLocale: "en" });
moment.defineLocale("en-MY", { parentLocale: "en" });
moment.defineLocale("en-VN", { parentLocale: "en" });
moment.defineLocale("en-SG", { parentLocale: "en" });
moment.defineLocale("en-SG-GIA", { parentLocale: "en-SG" });
moment.defineLocale("en-TH", { parentLocale: "en" });
moment.defineLocale("en-US-AMFAM", { parentLocale: "en-US" });
moment.defineLocale("en-US-Amica", { parentLocale: "en-US" });
moment.defineLocale("en-US-ElephantSubrogation", { parentLocale: "en-US" });
moment.defineLocale("en-US-Cure", { parentLocale: "en-US" });
moment.defineLocale("en-US-ECO", { parentLocale: "en-US" });
moment.defineLocale("en-JP", { parentLocale: "en" });
moment.defineLocale("en-GB-DemoPnC", {
    parentLocale: "en-GB",
    longDateFormat: { L: "DD/MM/YYYY" },
});
moment.defineLocale("en-GB-FirstCentral", {
    parentLocale: "en-GB",
    longDateFormat: { L: "DD/MM/YYYY" },
});
moment.defineLocale("en-GB-Hastings", { parentLocale: "en-GB" });
moment.defineLocale("en-GB-AxaUk", { parentLocale: "en-GB" });
moment.defineLocale("en-GB-SEUK", { parentLocale: "en-GB" });
moment.defineLocale("en-GB-IFB", {
    parentLocale: "en-GB",
    longDateFormat: { L: "DD/MM/YYYY" },
});
moment.defineLocale("en-GB-MarkerStudy", {
    parentLocale: "en-GB",
    longDateFormat: { L: "DD/MM/YYYY" },
});
moment.defineLocale("en-GB-Health", {
    parentLocale: "en-GB",
    longDateFormat: { L: "DD/MM/YYYY" },
});
moment.defineLocale("en-GB-BPGL", {
    parentLocale: "en-GB-Health",
    longDateFormat: { L: "DD/MM/YYYY" },
});
moment.defineLocale("en-GB-Qbe", { parentLocale: "en-GB" });
moment.defineLocale("en-GB-AxaIreland", { parentLocale: "en-GB" });
moment.defineLocale("en-US-USAA", { parentLocale: "en-US" });
moment.defineLocale("en-US-Health", { parentLocale: "en-US" });
moment.defineLocale("en-US-Assistance", { parentLocale: "en-US" });
moment.defineLocale("en-US-Health-Magellan", { parentLocale: "en-US" });
moment.defineLocale("en-CH", {
    parentLocale: "en",
    longDateFormat: {
        L: "DD.MM.YYYY",
        LLL: "DD.MM.YYYY LT",
    },
});
moment.defineLocale("en-CH-AxaSwitzerland", { parentLocale: "en-CH" });

// Spanish
moment.defineLocale("es-ES", { parentLocale: "es" });
moment.defineLocale("es-Assistance", { parentLocale: "es" });
moment.defineLocale("es-MX", { parentLocale: "es" });

// French
moment.defineLocale("fr-Assistance", { parentLocale: "fr" });
moment.defineLocale("fr-Health", { parentLocale: "fr" });
moment.defineLocale("fr-Health-Aesio", { parentLocale: "fr-Health" });
moment.defineLocale("fr-Health-Cegedim", { parentLocale: "fr-Health" });
moment.defineLocale("fr-Health-HarmonieMutuelle", { parentLocale: "fr-Health" });
moment.defineLocale("fr-Health-Mutex", { parentLocale: "fr-Health" });
moment.defineLocale("fr-Health-Mutex-Compliance", { parentLocale: "fr-Health" });
moment.defineLocale("fr-Health-Macif", { parentLocale: "fr-Health" });
moment.defineLocale("fr-Health-Macif-Disability", { parentLocale: "fr-Health-Macif" });
moment.defineLocale("fr-Health-Macif-Life", { parentLocale: "fr-Health-Macif" });
moment.defineLocale("fr-AG2R", { parentLocale: "fr" });
moment.defineLocale("fr-BE", { parentLocale: "fr" });
moment.defineLocale("fr-CA", { parentLocale: "fr" });
moment.defineLocale("fr-PnC", { parentLocale: "fr" });
moment.defineLocale("fr-FR", { parentLocale: "fr" });
moment.defineLocale("fr-GENERALI", { parentLocale: "fr" });
moment.defineLocale("fr-SG", { parentLocale: "fr" });
moment.defineLocale("fr-BE-ETHIAS", { parentLocale: "fr-BE" });
moment.defineLocale("fr-MacifSam", { parentLocale: "fr" });
moment.defineLocale("fr-PnC-ADFR", { parentLocale: "fr-PnC" });
moment.defineLocale("fr-PnC-AxaFr", { parentLocale: "fr-PnC" });
moment.defineLocale("fr-MACIF", { parentLocale: "fr-PnC" });
moment.defineLocale("fr-CH", {
    parentLocale: "fr",
    longDateFormat: {
        L: "DD.MM.YYYY",
        LLL: "DD.MM.YYYY LT",
    },
});
moment.defineLocale("fr-CH-AxaSwitzerland", { parentLocale: "fr-CH" });
moment.defineLocale("fr-CH-Generali", { parentLocale: "fr-CH" });

// Netherlands
moment.defineLocale("nl-BE", { parentLocale: "nl" });
moment.defineLocale("nl-NL", { parentLocale: "nl" });
moment.defineLocale("nl-BE-ETHIAS", { parentLocale: "nl-BE" });

// Italian
moment.defineLocale("it-IT", { parentLocale: "it" });
moment.defineLocale("it-Assistance", { parentLocale: "it" });
moment.defineLocale("it-IT-AXIT", { parentLocale: "it" });
moment.defineLocale("it-CH", {
    parentLocale: "it",
    longDateFormat: {
        L: "DD.MM.YYYY",
        LLL: "DD.MM.YYYY LT",
    },
});
moment.defineLocale("it-CH-AxaSwitzerland", { parentLocale: "it-CH" });

// Japanese
moment.defineLocale("ja-JP", { parentLocale: "ja" });
moment.defineLocale("ja-JP-Saison", { parentLocale: "ja-JP" });

// Portuguese
moment.defineLocale("pt-BR", { parentLocale: "pt" });
moment.defineLocale("pt-BR-Health", { parentLocale: "pt-BR" });
moment.defineLocale("pt-PT", { parentLocale: "pt" });

// used when sending date to the backend (keep the timezone)
moment.fn.toJSON = function () {
    moment.defaultFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
    return this.format();
};

export default {};
