export default [
    "da-DK",
    "de-CH",
    "de-CH-AxaSwitzerland",
    "de-CH-Generali",
    "de-DE",
    "de-DemoPnC",
    "fr-FR",
    "fr-PnC",
    "fr-ALFA",
    "fr-Assistance",
    "fr-Health",
    "fr-Health-Cegedim",
    "fr-Health-Macif",
    "fr-Health-Macif-Disability",
    "fr-Health-Macif-Life",
    "fr-Health-Mutex",
    "fr-Health-Mutex-Compliance",
    "fr-Health-HarmonieMutuelle",
    "fr-Health-Aesio",
    "fr-MacifSam",
    "en-GB",
    "en-GB-DemoPnC",
    "en-GB-FirstCentral",
    "en-GB-Hastings",
    "en-GB-AxaUk",
    "en-GB-SEUK",
    "en-GB-IFB",
    "en-GB-MarkerStudy",
    "en-GB-Health",
    "en-GB-BPGL",
    "en-GB-Qbe",
    "en-GB-AxaIreland",
    "en-EuraFrance",
    "en-EuraItaly",
    "en-ID",
    "en-ID-PruId",
    "en-MY",
    "en-MY-PruMy",
    "en-SG",
    "en-SG-GIA",
    "en-US",
    "en-US-Assistance",
    "en-US-Health",
    "en-US-Health-Magellan",
    "en-US-AMFAM",
    "en-US-Amica",
    "en-US-Cure",
    "en-US-DemoPnC",
    "en-US-ECO",
    "en-US-USAA",
    "en-US-STFM",
    "en-US-ELEPHANT",
    "en-US-ElephantSubrogation",
    "en-US-Subrogation",
    "en-US-Geico",
    "en-US-Liberty",
    "en-US-ALLI",
    "en-US-WFLD",
    "en-HK",
    "en-CA",
    "en-CA-PnC",
    "en-VN",
    "en-CH",
    "en-CH-AxaSwitzerland",
    "fr-EuraFrance",
    "nl-BE",
    "it-IT",
    "it-Assistance",
    "it-IT-AXIT",
    "it-EuraItaly",
    "it-CH",
    "it-CH-AxaSwitzerland",
    "it-CH-Generali",
    "nl-BE",
    "fr-PnC-AxaFr",
    "fr-PnC-ADFR",
    "fr-MACIF",
    "fr-BE",
    "fr-CA",
    "fr-CH",
    "fr-CH-AxaSwitzerland",
    "fr-CH-Generali",
    "fr-CH-Health",
    "es-ES",
    "es-Assistance",
    "es-MX",
    "es-MX-MLMX",
    "es-MX-AXME",
    "es-EuraFrance",
    "es-ES-Palig",
    "es-ES-ADSP",
    "es-ES-AGSP",
    "es-ES-AXSP",
    "fr-BE-ETHIAS",
    "nl-BE-ETHIAS",
    "pt-PT",
    "pt-BR",
    "pt-BR-Health",
    "ja-JP",
    "ja-JP-Saison",
];
