const themes = import.meta.glob<Record<string, string>>("../theme/*.json", { import: "default" });
const themesKeys = Object.keys(themes);

export const keyToUri = (key: string) => {
    const fileName = key.toLowerCase().split("/").at(-1);
    const fileNameWithoutExt = fileName?.split(".").at(0);
    return fileNameWithoutExt!;
};

const map: Record<string, () => Promise<Record<string, string>>> = {};

for (let idx = 0, length = themesKeys.length; idx < length; idx++) {
    const item = themesKeys[idx];
    map[keyToUri(item)] = themes[item];
}

export const loadClientTheme = async () => {
    const client = window.shiftContext.client.toLowerCase();

    if (map[client]) {
        const clientTheme = await map[client]();
        console.log("clientTheme", clientTheme);

        const root = document.querySelector<HTMLElement>(":root");

        Object.entries(clientTheme).forEach(([key, value]) => {
            root?.style.setProperty(`--${key}`, value);
        });
    }
};
