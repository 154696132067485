import React from "react";
import classNames from "classnames";

type World = any;

export interface FaProps extends World {
    name: string;
    kind?: "fa" | "shc";
    iconStyle?: "solid" | "regular" | "brands" | "light";
    flip?: "horizontal" | "vertical";
    border?: boolean;
    fw?: boolean;
    inverse?: boolean;
    li?: boolean;
    pull?: "left" | "right";
    pulse?: boolean;
    rotate?: 90 | 180 | 270;
    size?: "lg" | "2x" | "3x" | "4x" | "5x";
    spin?: boolean;
    stack?: "1x" | "2x";
    className?: string;
}

export const Fa = ({
    name,
    iconStyle = "solid",
    kind = "fa",
    size,
    border,
    flip,
    fw,
    inverse,
    li,
    pull,
    pulse,
    rotate,
    spin,
    stack,
    className: rawClassName,
    ...rest
}: FaProps) => {
    const core = {
        name,
        iconStyle,
        size,
        border,
        flip,
        fw,
        inverse,
        li,
        pull,
        pulse,
        rotate,
        spin,
        stack,
    };

    const classes = {
        fa: iconStyle === "solid",
        far: iconStyle === "regular",
        fab: iconStyle === "brands",
        fal: iconStyle === "light",
    };

    const step2 = (["border", "fw", "li", "inverse", "pulse", "spin"] as const).reduce(
        (prev, curr) => ({
            ...prev,
            [`fa-${curr}`]: core[curr],
        }),
        classes
    );

    const step3 = (["flip", "pull", "rotate", "stack"] as const).reduce((prev, curr) => {
        if (!core[curr]) {
            return prev;
        }
        return {
            ...prev,
            [`fa-${curr}-${core[curr]}`]: true,
        };
    }, step2);

    const lastStep = {
        ...step3,
        ...(size
            ? {
                  [`fa-${size}`]: true,
              }
            : {}),
        [`${kind}-${name}`]: true,
    };

    const className = classNames(lastStep, rawClassName);

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <span {...{ ...rest, className }} />
    );
};

export default Fa;
