import { HttpRequestError } from "./HttpRequestError.type";

class HttpRequestErrorBase extends Error implements HttpRequestError {
    xhrRequest: any;
    status: number;
    response: any;

    constructor(message: string, name: string, xhrRequest: any = {}, response: any = null) {
        super(message);

        // Ensure the name of this error is the same as the class name
        this.name = name;

        // Assign additional properties
        this.xhrRequest = xhrRequest;
        this.status = xhrRequest.status;
        this.response = response;

        // Capture stack trace
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        }
    }

    getXHRRequest() {
        return this.xhrRequest;
    }

    getResponse() {
        return this.response;
    }
}

export { HttpRequestErrorBase };
