import React from "react";
import PropTypes from "prop-types";
import ReactDocumentTitle from "react-document-title";

const DocumentTitle = ({ title, children }) => (
    <ReactDocumentTitle title={title ? `${title} - Shift Technology` : "Shift Technology"}>
        {children}
    </ReactDocumentTitle>
);

DocumentTitle.propTypes = {
    title: PropTypes.string,
};

DocumentTitle.displayName = "DocumentTitle";

export default DocumentTitle;
